@import "../../../../css/variables";

.client-detail-screen {
  .project-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    h2 {
      font-size: $heading-small;
      color: $darkGrey;
      font-weight: 500;
      margin: 0;
    }
  }
  .client-detail-content {
    .customer-review {
      border: 1px solid $haederStroke;
      border-radius: 1rem;
      h4 {
        padding: 2rem;
        border-bottom: 1px solid $haederStroke;
        font-size: $para2;
        margin: 0;
      }
      .review {
        padding: 2rem;
        font-size: $para1;
        color: $textLightColor;
      }
    }

  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .client-detail-screen {
    .client-detail-content {
      .client-detail-card {
        .profile-footer {
          .status {
            border-right: none !important;
            border-bottom: 1px solid $haederStroke;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .client-detail-screen {
    .client-detail-content {
      .client-detail-card {
        .profile-container {
          .image {
            img {
              width: 100%;
              max-width: 23rem;
            }
          }
        }
        .profile-footer {
          .status {
            border-right: none !important;
            border-bottom: 1px solid $haederStroke;
          }
        }
      }
    }
  }
}
