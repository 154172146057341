@import "../../css/variables";

.appoinment-table {
  thead {
    border: 0;
    th {
      border: 0;
      padding: 1rem;
      border-radius: 9px 9px 0px 0px;
      background: #fafbff;
      font-size: $para2;
      font-weight: 500;
      text-wrap: nowrap;
    }
  }
  tbody {
    .no-data-found {
      text-align: center;
    }
    td {
      font-size: $para2;
      font-weight: 500;
      padding: 1rem 1rem;
      color: $table-text;
      text-wrap: nowrap;
    }
  }
}
