@import "../../../css/variables";

.reminder-modal {
  font-family: $AlbertSans;
  z-index: 1050;
  .modal-content {
    margin: 0 auto;
    border: 0;
    .modal-body {
      padding: 3rem 4rem;
      h1 {
        font-size: $heading;
        color: $textColor;
        font-weight: 500;
        margin-bottom: 2rem;
        text-align: center;
      }
      .add-opportunity-form {
        width: 100%;
        label {
          font-size: $para3;
          font-weight: 700;
        }
        textarea {
          padding: 1.5rem;
          width: 100%;
          border: 1px solid $haederStroke !important;
          font-size: $para2;
        }
        .form-date-picker {
          .ant-select-selector,
          .ant-picker {
            height: 5.6rem;
            box-shadow: none;
            border-color: $haederStroke !important;
            align-items: center;
            width: 100%;
          }
          .ant-picker-input input {
            border: none !important;
            padding: 1.5rem 0.5rem;
          }
        }
        .select-available-time {
          h2 {
            font-size: $para2;
            margin-bottom: 2rem;
          }
          Button {
            width: 30%;
            height: 5rem;
            background-color: $blue;
            font-size: $para2;
          }
          .selected-time {
            background-color: #ffffff;
            color: $blue;
          }
        }
        .ant-select-selector,
        .ant-picker {
          height: 5.6rem;
          box-shadow: none;
          border-color: $haederStroke !important;
          align-items: center;
          width: 100%;
        }
      }
      .add-member-footer-btn {
        margin-top: 3rem;
        button {
          width: 14rem;
          height: 4.8rem;
          font-size: $para3;
          font-weight: 600;
          background-color: $blue;
          border-radius: 0.6rem;
        }
        .cancel-btn {
          background-color: transparent;
          color: $blue;
          border-color: $blue;
        }
      }
    }
  }
}
