@import "../../../css/variables";

.client-screen {
  height: 90%;
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    .heading {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
    }
    .create-project-btn {
      Button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border: 0;
        padding: 1rem;
        font-size: $para2;
        font-weight: 600;
        border-radius: 0.8rem;
        background-color: $dark-bule;
      }
    }
  }
  .list-table {
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .pagination-main {
    display: flex;
    justify-content: flex-end;
    .ant-pagination {
      display: flex;
      align-items: center;
      .ant-pagination-options {
        display: none;
      }
      .anticon {
        color: $blue;
        font-weight: 700;
        vertical-align: 0;
      }
      .ant-pagination-item {
        font-size: $para4;
        color: $lightGrey;
        border: 0.5px solid #e7e6e6;
        border-radius: 5px;
        &:hover {
          background-color: transparent;
        }
      }
      .ant-pagination-item-active a {
        border-radius: 5px;
        background-color: $blue;
        color: $white;
      }
    }
  }
}

@media (max-width: 576px) {
  .client-screen {
    .list-header {
      .heading {
        font-size: 3rem !important;
      }
      button {
        font-size: 1.4rem !important;
      }
    }
  }
}
