@import "../../css/variables";

.appointment-detail-card {
    background-color: $off-white;
    border-radius: 1rem;
    padding: 2.5rem;
    .profile-container {
      margin-right: auto;
      .image {
        width: 100%;
        max-width: 38rem;
        img {
          width: 100%;
          height: 28rem;
          aspect-ratio: 16/12;
          object-fit: cover;
          border-radius: 0.8rem;
        }
      }
      .detail {
        button {
          background-color: $blue;
          border-radius: 2.5rem;
          padding: 0.5rem 1.5rem;
          font-size: $para4;
          line-height: 1.5rem;
        }
        h2 {
          display: flex;
          align-items: center;
          font-size: $heading-medium2;
          font-weight: 700;
          color: $textColor;
          gap: 1.8rem;
          span {
            font-size: $para3;
            color: $table-text;
            letter-spacing: 1px;
            text-transform: uppercase;
          }
        }
        h4 {
          font-size: $para1;
          color: $textLightColor;
          margin: 1rem 0 0;
        }
      }
    }
    .complete-btn {
      width: 100%;
      height: 4.8rem;
      font-size: $para3;
      background-color: $blue;
      margin: 2rem 0 1.5rem;
    }
    .status-detail {
      font-size: $para2;
      font-weight: 700;
      .status {
        color: #159900;
        margin: 0 1rem;
      }
      .date {
        font-weight: 500;
      }
    }
  }