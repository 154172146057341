@import "../../../css/variables";

.payment-modal-main {
  font-family: $AlbertSans;
  z-index: 1050;
  .modal-content {
    margin: 0 auto;
    border: 0;
    .modal-body {
      padding: 2.5rem;
      label {
        font-size: $para3;
        font-weight: 700;
        text-wrap: nowrap;
      }
      input,
      textarea {
        padding: 1.5rem;
        width: 100%;
        border: 1px solid $haederStroke;
        font-size: $para2;
      }
      .ant-select-selector,
      .ant-picker {
        height: 5.6rem;
        box-shadow: none;
        border-color: $haederStroke;
        align-items: center;
        width: 100%;
      }
      .ant-picker-input input {
        border: none;
        padding: 1.5rem 0.5rem;
      }
      .footer-btn {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
        Button {
          width: 50%;
          padding: 1rem;
          font-size: $para3;
          background-color: $blue;
          font-weight: 600;
          border-radius: 0.8rem;
        }
        .cancel-btn {
          background-color: $white;
          color: $blue;
          border-color: $blue;
        }
      }
    }
  }
}
