@import "../../css/variables";

.table-header {
  .heading {
    font-size: $para2;
    font-weight: 600;
    color: $darkGrey;
  }
  .search-section {
    width: 30%;
    .input-group {
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #e7e6e6;
      .form-control {
        border: 0;
        padding: 1rem;
        font-size: $para2;
        color: #9999a8;
        font-weight: 400;
        background-color: transparent;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:focus-visible {
          outline: none;
        }
      }
      .input-group-text {
        background-color: transparent !important;
        border: 0 !important;
      }
    }
  }
  .dropdown {
    .dropdown-toggle {
      background-color: transparent;
      color: $black;
      font-size: $para3;
      font-weight: 600;
      &::after {
        content: url("../../assets/Icon/down-arrow-icon.svg");
        border: 0;
        margin-left: 1rem;
        vertical-align: 0;
      }
    }
    .droup-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      font-size: $para3;
      font-weight: 500;
      color: $darkGrey;
      input {
        width: 20px;
        height: 20px;
        &:is(:focus, :focus-visible) {
          outline: none;
          border: 0;
        }
      }
    }
    .dropdown-menu.show {
      transform: translate(-20px, 39px) !important;
      padding: 1rem;
      width: 100% !important;
    }
  }
}
