@import "../../../css/variables";

.appointment-table-main {
  font-family: $AlbertSans;
  .list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    .heading {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
    }
    .create-project-btn {
      Button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border: 0;
        padding: 1rem;
        font-size: $para3;
        font-weight: 600;
        color: $white;
        border-radius: 0.8rem;
        background-color: $dark-bule;
      }
    }
  }
  .categories-text {
    font-size: $para3;
    font-weight: 600;
    color: $lightGrey;
    padding: 1rem;
    border-radius: 0.8rem;
    cursor: pointer;
  }
  .nav-tabs {
    gap: 1rem;
    padding-bottom: 1rem;
    .search-section {
      .input-group {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e7e6e6;
        .form-control {
          border: 0;
          padding: 1rem;
          font-size: $para2;
          color: #9999a8;
          font-weight: 400;
          background-color: transparent;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          &:focus-visible {
            outline: none;
          }
        }
        .input-group-text {
          background-color: transparent !important;
          border: 0 !important;
        }
      }
    }
  }
  .appoinment-tab {
    > .nav-item {
      &:nth-child(4) {
        max-width: 30rem;
        width: 100%;
        margin-left: auto;
        button {
          width: 100%;
          padding: 0 !important;
        }
      }
    }
  }
  .nav-link {
    border-radius: 1rem;
    padding: 1rem 2rem;
    font-size: $para3;
    font-weight: 600;
    color: $lightGrey;
    border: 0;
  }
  .nav-link.active {
    color: $white;
    background-color: $blue;
  }
  .pagination-main {
    display: flex;
    justify-content: flex-end;
    .ant-pagination {
      display: flex;
      align-items: center;
      .ant-pagination-options {
        display: none;
      }
      .anticon {
        color: $blue;
        font-weight: 700;
        vertical-align: 0;
      }
      .ant-pagination-item {
        font-size: $para4;
        color: $lightGrey;
        border: 0.5px solid #e7e6e6;
        border-radius: 5px;
        &:hover {
          background-color: transparent;
        }
      }
      .ant-pagination-item-active a {
        border-radius: 5px;
        background-color: $blue;
        color: $white;
      }
    }
  }
}

@media (max-width: 590px) {
  .appointment-table-main {
    .list-header {
      .heading {
        font-size: 2.5rem !important;
      }
      button {
        font-size: 1.4rem !important;
      }
    }
    .appoinment-tab {
      > .nav-item {
        &:nth-child(4) {
          max-width: 100%;
          width: 100%;
          margin-left: auto;
          button {
            width: 100%;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
