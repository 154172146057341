@import "../../../css/variables";

.opportunity-detail-content {
  .opportunity-header-card {
    width: 50%;
    background-color: $lightPurple;
    border-radius: 0.8rem;
    padding: 1rem;
    .image {
      margin: auto;
      img {
        width: 8rem;
        height: 8rem;
        object-fit: cover;
      }
    }
    .opportunity-card-detail {
      h2 {
        font-size: $para1;
        font-weight: 700;
      }
      h6 {
        font-size: $para2;
        font-weight: 700;
        color: $textLightColor;
      }
      .id {
        padding: 0.5rem 1rem;
        width: fit-content;
        font-size: $para3;
        background-color: $white;
        border-radius: 0.8rem;
        margin: 0;
      }
    }
    .change-btn {
      height: fit-content;
      width: fit-content;
    }
    button {
      width: 18rem;
    }
  }
  button {
    font-size: $para2;
    background-color: $blue;
    height: 5rem;
  }
  .member-activity-accordion {
    border: 1px solid $haederStroke;
    border-radius: 1rem;
    margin: 2rem 0;
    overflow: hidden;
    .accordion-button:not(.collapsed) {
      box-shadow: none;
      &:focus {
        box-shadow: none;
        outline: 0;
      }
      &:focus-visible {
        box-shadow: none;
        outline: 0;
      }
    }
    .accordion-item {
      background-color: transparent;
      &:focus {
        box-shadow: none;
        outline: 0;
      }
      &:focus-visible {
        box-shadow: none;
        outline: 0;
      }
    }
    .accordion-button {
      font-size: $para2;
      font-weight: 700;
      color: $textColor;
      font-family: $AlbertSans;
      height: 5rem;
      border-radius: 1rem;
      background-color: transparent;
      &:focus {
        box-shadow: none;
        outline: 0;
      }
      &:focus-visible {
        box-shadow: none;
        outline: 0;
      }
    }
    .add-btn {
      background-color: $blue;
      height: auto;
    }
    .accordion-body {
      padding: 1.5rem 2rem;
      border-top: 1px solid $haederStroke;
      .daily-activities {
        font-size: $para2;
        font-weight: 500;
      }
      .profile {
        margin-top: 1rem;
        .image {
          img {
            width: 3rem;
            height: 3rem;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .content {
          .name {
            font-size: $para2;
            font-weight: 500;
          }
          .time {
            font-size: $para4;
            font-weight: 500;
          }
        }
      }
    }
  }
  .detail {
    margin-top: 4rem;
    width: 60%;
    ol {
      font-size: $para2;
      p {
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
      > li {
        margin-bottom: 2.5rem;
      }
      ul {
        list-style-type: disc;
        padding-left: 1rem;
      }
    }
  }
}
