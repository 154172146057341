@import "../../../../css/variables";

.project-detail-task {
  .total-task {
    background-color: $headerBg;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    h4 {
      font-size: $para2;
      font-weight: 700;
      margin: 0;
    }
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0 1.5rem;
      background-color: $white;
      color: $blue;
      border-color: $blue;
      font-size: $para3;
      height: 4rem;
    }
  }
  .tab-header {
    margin-bottom: 1rem;
    .task-btn {
      width: 40%;
      button {
        background-color: #d2992b;
        border: none;
        font-size: $para3;
        border-radius: 0.8rem;
      }
      .ongoing {
        background-color: $dark-bule;
      }
      .completed {
        background-color: #159900;
      }
    }
    :nth-child(2),
    :nth-child(3),
    :nth-child(4) {
      width: 20%;
      margin: 0;
      font-size: $para2;
    }
  }
  .accordion-item {
    margin-bottom: 0.5rem;
    border: 1px solid $haederStroke;
    border-radius: 1rem;
    .accordion-header {
      .accordion-button {
        background-color: $white;
        box-shadow: none;
        border: 1px solid $haederStroke;
        border-radius: 1rem 1rem 0 0;
      }
      .collapsed {
        border-radius: 1rem;
      }
      h4 {
        font-size: $para2;
        &:nth-child(1) {
          width: 40%;
          margin: 0;
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          width: 20%;
          margin: 0;
        }
      }
    }
    .accordion-body {
      p {
        font-size: $para3;
        margin-bottom: 1rem;
      }
      .comment {
        font-size: $para2;
        font-weight: 500;
        margin-bottom: 0.5rem;
        ol {
          li {
            font-size: $para3;
            font-weight: 400;
          }
        }
      }
      button {
        font-size: $para3;
        width: 9rem;
        height: 4rem;
        border-radius: 0.8rem;
        background-color: $blue;
      }
      .edit-btn {
        background-color: $white;
        color: $blue;
      }
      .delete-btn {
        background-color: $white;
        color: $textLightColor;
        border-color: $textLightColor;
      }
      .comment-btn {
        background-color: $dark-bule;
        border: none;
        margin-left: auto;
      }
    }
  }
}
