@import "../../css/variables";

.overview-section {
    gap: 1.5rem;
    margin-top: 2.2rem;
    .part {
      padding: 2rem;
      width: 25%;
      border-radius: 1rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .number {
        font-size: $heading;
        font-weight: 700;
      }
      .title {
        font-size: $para2;
        margin: 0 auto;
      }
      &:nth-child(1) {
        background-color: #e8edff;
      }
      &:nth-child(2) {
        background-color: #e0fde4;
      }
      &:nth-child(3) {
        background-color: #fdfce0;
      }
      &:nth-child(4) {
        background-color: #ffeadb;
      }
    }
  }