@import "../../../../../css/variables";

.proposal-preview-screen {
  height: 90%;
  .proposal-preview-header {
    margin-bottom: 1rem;
    .project-heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      h2 {
        font-size: $heading-small;
        color: $darkGrey;
        font-weight: 500;
        margin: 0;
      }
    }
    .current-stage {
      border: 1px solid $textColor;
      border-radius: 1rem;
      padding: 1rem 1.5rem;
      h4 {
        font-size: $para3;
        margin: 0;
        color: $textLightColor;
      }
      h3 {
        font-size: $para2;
        font-weight: 600;
        margin: 0;
      }
    }
  }
  .proposal-preview-content {
    background-color: $headerBg;
    padding: 2.5rem;
    border-radius: 1rem;
    .left {
      h4 {
        font-size: $para2;
      }
    }
    .right {
      text-align: end;
      h4 {
        font-size: $para2;
        font-weight: 700;
        margin-bottom: 0.5rem;
        span {
          font-weight: 500;
        }
      }
    }
    p {
      font-size: $para2;
      color: $textLightColor;
    }
    h6 {
      font-size: $para2;
      font-weight: 700;
      margin-bottom: 1rem;
    }
    h5 {
      font-size: $para2;
      color: $textLightColor;
      margin: 0;
    }
    .detail {
      width: 80%;
      ol {
        font-size: $para2;
        p {
          font-weight: 700;
          margin: 0.5rem 0;
          span {
            font-weight: 500;
            margin-left: 0.5rem;
          }
        }
        ul {
          list-style-type: disc;
          padding-left: 1rem;
        }
      }
    }
  }
  .footer-btn {
    padding: 3rem 0;
    gap: 1.5rem;
    button {
      font-size: $para3;
      padding: 1.2rem 1.6rem;
      border-radius: 0.8rem;
      border: none;
      background-color: $dark-bule;
      width: 20rem;
    }
    .edit-btn {
      background-color: $blue;
      svg {
        color: white;
        margin-right: 0.5rem;
      }
    }
  }
}

@media (max-width: 576px) {
  .proposal-preview-screen {
      .proposal-preview-content {
      .detail {
        width: 100%;
      }
    }
  }
}
