@import "../../css/variables";

.chat-section {
    box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
    border-radius: 1rem;
    height: 100%;
    .header {
      height: 6.6rem;
      padding: 1.2rem 1.8rem 1.2rem 2rem;
      border-bottom: 1px solid $haederStroke;
      h2 {
        display: flex;
        align-items: center;
        font-size: $heading-medium;
        font-weight: 700;
        margin: 0;
        img {
          margin: 0 0.5rem 0 1.5rem;
        }
        span {
          font-size: $para3;
          font-weight: 500;
          color: $textLightColor;
        }
      }
      .search-section {
        width: 60%;
        .input-group {
          display: flex;
          align-items: center;
          background-color: $headerBg;
          border-radius: 1rem;
          .form-control {
            border: none;
            padding: 1.2rem;
            font-size: $para2;
            background-color: transparent;
            &:focus {
              box-shadow: none;
            }
          }
          img {
            padding: 1rem 1.2rem;
            cursor: pointer;
          }
        }
      }
    }
    section {
      padding: 2rem 2.8rem;
      height: 90%;
      .chat-section-content {
        .messages {
          display: flex;
          margin-bottom: 0.5rem;
          justify-content: flex-end;
          .message-contents {
            display: flex;
            flex-direction: column;
            max-width: 80%;
            align-items: flex-end;
            .time {
              font-size: 0.9rem;
              color: $lightGrey;
              font-weight: 600;
              font-family: $AlbertSans;
              margin-bottom: 0.2rem;
            }
            p {
              background-color: #2b4ed2;
              padding: 1rem 2rem;
              font-family: $AlbertSans;
              color: $white;
              border-radius: 1rem 1rem 0rem 1rem;
              font-size: $para3;
              margin: 0;
            }
          }
          &.owner {
            flex-direction: row-reverse;
            .message-contents {
              align-items: flex-start;
            }
            p {
              background-color: $skyBlue;
              color: $textColor;
              font-family: $AlbertSans;
              font-size: $para3;
              font-weight: 500;
              border-radius: 1rem 1rem 1rem 0rem;
            }
          }
        }
      }
      .send-message-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $purple;
        padding: 1.5rem;
        height: 50px;
        input {
          width: 100%;
          border: 0;
          font-size: $para2;
          font-weight: 400;
          &:focus {
            border: 0;
            outline: none;
          }
          &:focus-visible {
            border: 0;
            outline: none;
          }
        }
        .icon-group {
          display: flex;
          gap: 1.5rem;
          img {
            width: 3.2rem;
            height: 3.2rem;
            cursor: pointer;
          }
        }
      }
    }
  }