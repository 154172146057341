@import "../../../css/variables";

.client-screen-main {
  font-family: $AlbertSans;
  .project-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    h2 {
      font-size: $heading-small;
      color: $darkGrey;
      font-weight: 500;
      margin: 0;
    }
  }
  .history-section-main {
    border-radius: 11px 0px 0px 11px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
    height: 100%;
    .history-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.2rem solid $purple;
      padding: 0rem 2rem;
      height: 6.8rem;
      h5 {
        font-size: $heading-small;
        color: $darkGrey;
        font-weight: 500;
      }
      span {
        color: $lightGrey;
        font-size: $para3;
        font-weight: 500;
      }
    }
    .history-content {
      padding: 1rem;
      .content-title {
        font-size: $para3;
        font-weight: 400;
        color: $darkGrey;
        margin-top: 1.8rem;
      }
      .history-text {
        .text {
          background-color: $skyBlue;
          padding: 1rem;
          font-size: $para3;
          font-weight: 500;
          color: $darkGrey;
          border-radius: 1.1rem;
          margin: 1rem 0 0;
        }
      }
    }
  }
  .project-chat-section {
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
    .project-chat-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0rem 2rem;
      height: 6.8rem;
      border-bottom: 0.2rem solid $purple;
      .chat-logo {
        background-color: $dark-bule;
        padding: 1rem 0.5rem;
        border-radius: 1.6rem;
        width: 4.4rem;
        img {
          width: 100%;
        }
      }
      .new-chat-btn Button {
        padding: 1rem 2rem;
        background-color: $blue;
        font-size: $para3;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 1rem;
        border-radius: 0.8rem;
        cursor: pointer;
      }
    }
    .project-chat-content {
      height: 80%;
      .create-now {
        padding: 1rem 2rem;
        display: flex;
        align-items: center;
        border-top: 0.2rem solid $purple;
        justify-content: flex-end;
        gap: 1rem;
        span {
          font-size: $para4;
          color: $lightGrey;
          font-weight: 400;
        }
        Button {
          background-color: $blue;
          font-size: $para4;
          padding: 0.8rem 2rem;
          border-radius: 0.8rem;
          font-weight: 500;
        }
      }
      .input {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $purple;
        padding: 1rem 2rem;
        height: 50px;
        input {
          width: 100%;
          border: 0;
          font-size: $para2;
          font-weight: 400;
          &:focus {
            border: 0;
            outline: none;
          }
          &:focus-visible {
            border: 0;
            outline: none;
          }
        }
        .icon-group {
          display: flex;
          gap: 1rem;
          cursor: pointer;
        }
      }
    }
  }
  .project-filter-section {
    border-radius: 0px 11px 11px 0px;
    background: #f4f4ff;
    box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
    height: 100%;
    .view-all-btn {
      padding: 1rem;
      border-bottom: 0.2rem solid $purple;
      height: 6.8rem;
      Button {
        background-color: $dark-bule;
        border: 0;
        width: 100%;
        height: 100%;
        font-size: $para2;
        border-radius: 0.8rem;
        font-weight: 600;
      }
    }
    .project-filter {
      padding: 1.5rem;
      .project-details {
        margin-bottom: 1rem;
        .details-main {
          background-color: $white;
          padding: 1.5rem 2rem;
          border-radius: 1rem;
          .details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: $para3;
            font-weight: 400;
            color: $darkGrey;
            margin-bottom: 1rem;
          }
        }
      }
      .ai-filters {
        .filter-main {
          background-color: $white;
          padding: 1.5rem 2rem;
          border-radius: 1rem;
          .filter-heading {
            font-size: $para2;
            font-weight: 700;
            margin-bottom: 1rem;
          }
          .filters-tag {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            .tag {
              background-color: $darkGrey;
              border-radius: 0.8rem;
              padding: 1rem 1.5rem;
              font-size: $para3;
              font-weight: 500;
              color: $white;
            }
          }
        }
      }
    }
  }
}
