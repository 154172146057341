@import "../../../../../css/variables";

.create-proposal-screen {
  font-family: $AlbertSans;
  .create-proposal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    .heading {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
    }
  }
  .create-proposal-form {
    width: 100%;
    .client-information {
      border-bottom: 1px solid $haederStroke;
      .ant-checkbox-wrapper {
        display: flex;
        font-size: $para2;
        font-weight: 700;
      }
    }
    label {
      font-size: $para3;
      font-weight: 700;
      text-wrap: nowrap;
    }
    label::before {
      display: none !important;
    }
    input,
    textarea {
      padding: 1.5rem;
      width: 100%;
      border: 1px solid $haederStroke !important;
      font-size: $para2;
    }
    .ant-select-selector,
    .ant-picker {
      height: 5.6rem;
      box-shadow: none;
      border-color: $haederStroke !important;
      align-items: center;
      width: 100%;
    }
    .ant-picker-input input {
      border: none;
      padding: 1.5rem 0.5rem;
    }
    .client-item-input,
    .duration-item-input {
      .ant-form-item-explain-error {
        margin-top: 18px;
        margin-bottom: 8px;
      }
    }
    .ant-upload-select {
      width: 100%;
      border: 1px solid $haederStroke;
      height: 5.6rem;
      border-radius: 0.6rem;
      .ant-upload {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 1.5rem;
        svg {
          font-size: 2rem;
          color: $blue;
          margin-right: 2rem;
        }
      }
    }
    .part {
      > div {
        width: 33%;
      }
    }
    h4 {
      font-size: $para2;
      font-weight: 700;
      padding-bottom: 1rem;
      border-bottom: 1px solid $haederStroke;
      margin: 2rem 0 1rem;
    }
    .add-btn {
      background-color: transparent;
      border: none;
      color: $blue;
      font-size: $para2;
      display: flex;
      gap: 1.5rem;
      padding: 0;
      cursor: pointer;
      margin: 0.5rem 0 3rem;
    }
    .add-more-section {
      border-top: 1px solid $haederStroke;
    }
    .more-section {
      padding-top: 3rem;
    }
    .section-preview {
      padding-left: 1rem;
      margin: 1.5rem 0rem;
      p {
        font-weight: 700;
        margin: 0.5rem 0;
        .section-index {
          font-weight: 800;
          margin-right: 1rem;
        }
        span {
          font-weight: 500;
          margin-left: 0.5rem;
        }
      }
    }
    .remove-icon {
      font-size: 3rem;
      color: #ff4d4f;
      margin-top: 1rem;
      cursor: pointer;
    }
    .add-member-footer-btn {
      margin-top: 6rem;
      button {
        width: 50%;
        height: 4.8rem;
        font-size: $para3;
        font-weight: 600;
        background-color: $blue;
        border-radius: 0.6rem;
      }
      .cancel-btn {
        background-color: transparent;
        color: $blue;
        border-color: $blue;
      }
      .preview-btn {
        background-color: $dark-bule;
        border-color: $dark-bule;
      }
      .add-section-btn {
        width: 10rem;
      }
    }
    .form-multiple-input{
      margin-bottom: 1rem;
    }
  }
}

.add-more-section-modal {
  .modal-content {
    border: 0;
    .modal-body {
      border: 0;
      padding: 2.5rem;
      label {
        font-size: $para3;
        font-weight: 700;
      }
      label::before {
        display: none !important;
      }
      input,
      textarea {
        padding: 1.5rem;
        width: 100%;
        border: 1px solid $haederStroke !important;
        font-size: $para2;
      }
      .footer-btn {
        margin-top: 2.5rem;
        button {
          width: 50%;
          height: 4.8rem;
          font-size: $para3;
          font-weight: 600;
          background-color: $blue;
          border-radius: 0.6rem;
        }
        .cancel-btn {
          background-color: transparent;
          color: $blue;
          border-color: $blue;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .create-proposal-screen {
    .create-proposal-header {
      .heading {
        font-size: 3rem !important;
      }
    }
  }
}
