@import "../../css/variables";

.section-content {
    margin-top: 4rem;
    gap: 2rem;
    .monthly-lead-chart {
      .chart {
        height: 30rem;
        border: 1px solid $haederStroke;
        border-radius: 0.8rem;
        padding: 1.6rem;
      }
      .title {
        font-size: $heading-small;
        margin-bottom: 1rem;
        h2 {
          margin: 0;
        }
      }
    }
    .top-performed-plan {
      .title {
        font-size: $heading-small;
        margin-bottom: 1rem;
      }
      .plan-card {
        background-color: #dfe6ff;
        border-radius: 0.8rem;
        padding: 2.5rem;
        .plan-title {
          font-size: $para2;
          width: 35%;
          margin-bottom: 1rem;
          &:nth-child(3) {
            width: 30%;
          }
        }
        .dark .plan-title {
          font-size: $heading-small;
          font-weight: 700;
        }
        .blue .plan-title {
          font-size: $heading-small;
          font-weight: 700;
          color: $blue;
        }
        .total-activity {
          border-top: 1px dashed $textLightColor;
          padding-top: 1.5rem;
          .plan-title {
            font-size: $heading-small;
            font-weight: 700;
            &:nth-child(1) {
              width: 66%;
            }
          }
        }
      }
    }
  }