@import "../../css/variables";

.dashboard-section {
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
    }
    .upgrad-plan {
      gap: 26px;
      p {
        font-size: $para2;
      }
      .upgrad-btn {
        font-size: $para2;
        padding: 1rem;
        background-color: $dark-bule;
        border: none;
      }
    }
  }
  .section-content {
    margin-top: 2.2rem;
    .left {
      .project {
        .box {
          border: 1px solid $haederStroke;
          border-radius: 0.8rem;
          padding: 2rem 3rem;
          .title {
            font-size: $para3;
            font-weight: 600;
            letter-spacing: 1.61px;
            color: $textLightColor;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
            text-wrap: nowrap;
          }
          h2 {
            font-size: 3.8rem;
            margin: 0;
          }
        }
      }
      .monthly-lead-chart {
        border: 1px solid $haederStroke;
        border-radius: 0.8rem;
        margin-top: 2rem;
        padding: 1.6rem;
        .title {
          font-size: $heading-small;
          margin-bottom: 1rem;
        }
        .dashboard-chart {
          height: 90%;
        }
      }
    }
  }
}

@media (max-width: 1200px) and (min-width: 576px) {
  .dashboard-section {
    .section-content {
      .project {
        .box {
          width: 48%;
          padding: 2rem !important;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .dashboard-section {
    .section-header {
      .heading {
        font-size: 3rem !important;
      }
      button {
        font-size: 1.4rem !important;
      }
    }
    .section-content {
      .upcoming-event {
        table {
          tbody {
            tr {
              td {
                font-size: $para3 !important;
                .img {
                  img {
                    width: 32px;
                    height: 32px;
                    object-fit: cover;
                  }
                }
                .content {
                  font-size: $para4;
                  .name {
                    color: $darkGrey;
                  }
                  .designation {
                    color: $lightGrey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
