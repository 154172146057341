@import "../../../../css/variables";

.project-detail-main {
  font-family: $AlbertSans;
  .project-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    .title {
      display: flex;
      align-items: center;
      gap: 1rem;
      h2 {
        font-size: $heading-small;
        color: $darkGrey;
        font-weight: 500;
        margin: 0;
      }
    }
    .create-project-btn {
      Button {
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 0;
        padding: 1rem;
        border-radius: 0.8rem;
        background-color: $dark-bule;
        .btn-text {
          font-size: $para3;
          font-weight: 600;
        }
      }
    }
  }

  .project-detail-tabs {
    margin-top: 1.6rem;
    .nav-tabs {
      gap: 1rem;
      padding-bottom: 1rem;
    }
    .nav-link {
      border-radius: 1rem;
      padding: 1rem;
      font-size: $para3;
      font-weight: 600;
      color: $lightGrey;
      border: 0;
    }
    .nav-link.active {
      color: $white;
      background-color: $blue;
    }
    
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .project-detail-main {
    .project-detail-card {
      .img,
      .detail {
        width: 48%;
      }
    }
  }
}

@media (max-width: 768px) {
  .project-detail-main {
    .project-detail-card {
      .img {
        width: 100%;
      }
      .detail {
        width: 100%;
        padding: 1.5rem 0;
        .left {
          h3 {
            font-size: 2.5rem;
            margin-bottom: 1rem;
          }
          p {
            font-size: $para3;
          }
        }
        .right {
          .ant-avatar-group {
            margin-bottom: 1rem;
            .title {
              font-size: $para3;
            }
          }
          .date {
            h3 {
              font-size: $para2;
            }
            h4 {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }
}
