@import "../../../css/variables";

.ai-popup-main {
  font-family: $AlbertSans;
  .modal-content {
    border: 0;
    .modal-header {
      background-color: $dark-bule;
      border: 0 !important;
      color: $white;
      padding: 2rem;
      .modal-title {
        font-size: $heading-medium;
        font-weight: 600;
      }
      .btn-close {
        filter: invert(100%) sepia(8%) saturate(7467%) hue-rotate(66deg)
          brightness(200%) contrast(94%);
        opacity: 1;
      }
    }
    .modal-body {
      border: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .ai-message {
        margin-top: 1rem;
        font-size: 3rem;
        color: $darkGrey;
        font-weight: 700;
      }
      .question {
        font-size: $heading-small;
        font-weight: 500;
        color: $darkGrey;
        margin-top: 1rem;
      }
      .topic {
        font-size: $heading-small;
        font-weight: 500;
        color: $darkGrey;
        margin-top: 1rem;
      }
      .topic-list {
        width: 408px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 24px;
        margin: 6rem 0 10rem;
        .list {
          width: 30%;
          padding: 2.5rem;
          background-color: #28264f80;
          border-radius: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1rem;
          cursor: pointer;
          h4 {
            font-size: $para2;
            margin: 0;
            color: $white;
          }
          &:hover {
            background-color: #28264f;
            .icon img {
              filter: invert(99%) sepia(0%) saturate(7450%) hue-rotate(223deg)
                brightness(127%) contrast(89%);
            }
          }
        }
      }
    }
  }
}
