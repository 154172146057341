@import "../../../css/variables";

.project-detail-upload-document-modal {
  font-family: $AlbertSans;
  z-index: 1050;
  .modal-content {
    margin: 0 auto;
    border: 0;
    .modal-body {
      padding: 2.5rem;
      .header {
        h1 {
          font-size: $heading-medium;
          font-weight: 600;
          margin: 0;
        }
        svg {
          font-size: 2rem;
          cursor: pointer;
        }
      }
      .add-document-section {
        gap: 1rem;
        .document-card {
          border: 1px solid $haederStroke;
          border-radius: 1rem;
          .img {
            width: 16rem;
            aspect-ratio: 1/1;
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: linear-gradient(
              180deg,
              #f7f8fd 0%,
              rgba(255, 255, 255, 0) 100%
            );
            border: 0.5px solid rgba(255, 255, 255, 0);
            svg {
              font-size: 4rem;
            }
            p {
              font-size: $para4;
              text-align: center;
              margin: 1rem 0 0 0;
            }
          }
        }
        label {
          font-size: $para3;
          font-weight: 700;
          text-wrap: nowrap;
        }
        input {
          padding: 1rem 1.5rem;
          width: 100%;
          border: 1px solid $haederStroke;
          font-size: $para2;
        }
        .footer-btn {
          display: flex;
          justify-content: center;
          margin-top: 4rem;
          Button {
            width: 50%;
            padding: 1rem;
            font-size: $para3;
            background-color: $blue;
            font-weight: 600;
            border-radius: 0.8rem;
          }
          .cancel-btn {
            background-color: $white;
            color: $blue;
            border-color: $blue;
          }
        }
      }
    }
  }
}
