@import "../../css/variables";

.due-payment-table {
  thead {
    border: 0;
    th {
      border: 0;
      padding: 1rem;
      border-radius: 9px 9px 0px 0px;
      background: #fafbff;
      font-size: $para2;
      font-weight: 500;
      text-wrap: nowrap;
    }
  }
  tbody {
    vertical-align: middle;
    td {
      font-size: $para2;
      font-weight: 500;
      padding: 1rem 1rem;
      color: $table-text;
      text-wrap: nowrap;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: 30%;
      }
    }
    .remind-btn {
      width: 100%;
      font-size: $para4;
      font-weight: 500;
      background-color: transparent;
      color: $blue;
      border-color: $blue;
      padding: 0.5rem 1.5rem;
    }
  }
}
