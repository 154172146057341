@import "../../../../css/variables";

.project-detail-card {
    background: linear-gradient(
      180deg,
      #d5dcf6 0.29%,
      rgba(239, 238, 238, 0.29) 123.85%
    );
    padding: 1.5rem;
    border-radius: 1rem;
    .img {
      border-radius: 0.6rem;
      overflow: hidden;
      width: 35%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .detail {
      padding: 0 2rem;
      width: 65%;
      .left {
        h3 {
          font-size: $heading;
          margin-bottom: 1.5rem;
        }
        img {
          width: 2.2rem;
          height: 2.2rem;
          margin-right: 1.2rem;
        }
        p {
          font-size: $para2;
          margin: 0;
        }
      }
      .right {
        .ant-avatar-group {
          margin-bottom: 2rem;
          .title {
            margin-left: 1rem;
            font-size: $para2;
            font-weight: 500;
          }
        }
        .date {
          h3 {
            font-size: $para1;
            font-size: 500;
            margin-bottom: 0.5rem;
          }
          h4 {
            font-size: $para4;
            margin-bottom: 1rem;
          }
        }
      }
      .detail-icon {
        gap: 1.5rem;
        button {
          height: 5rem;
          font-size: $para3;
          font-weight: 600;
          border-radius: 0.8rem;
          text-wrap: nowrap;
          svg {
            font-size: 2rem;
            margin-right: 1rem;
          }
        }
        .assign-btn {
          background-color: $blue;
        }
        .change-btn {
          background-color: transparent;
          color: $blue;
          border-color: $blue;
        }
      }
    }
  }