@import "../../css/variables";

.chat-box {
  border-radius: 11px 0px 0px 11px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
  padding: 2rem;
  .chat-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: $para2;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
      span {
        background-color: #f34c4c;
        padding: 0.5rem;
        color: $white;
        border-radius: 50%;
        font-size: $para4;
        font-weight: 600;
        margin-left: 0.2rem;
      }
    }
    .see-all {
      font-size: $para2;
      color: $dark-bule;
      font-weight: 500;
      font-family: $AlbertSans;
    }
  }
  .search-section {
    margin: 1rem 0rem;
    .input-group {
      display: flex;
      align-items: center;
      background-color: $headerBg;
      .form-control {
        border: none;
        padding: 1rem;
        font-size: $para2;
        background-color: transparent;
        &:focus {
          outline: none;
          box-shadow: none;
        }
        &:focus-visible {
          outline: none;
        }
      }
      .input-group-text {
        background-color: transparent !important;
        border: 0 !important;
      }
    }
  }
  .other-messages {
    margin: 1rem 0rem;
    .person-details {
      background-color: $lightPurple;
      padding: 0.9rem;
      border-radius: 1.1rem;
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .profile-img {
        width: 6.5rem;
        height: 6.5rem;
        display: flex;
        justify-content: center;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1.6rem;
        }
        .circle {
          width: 1.6rem;
          height: 1.6rem;
          background-color: white;
          border-radius: 50%;
          position: absolute;
          display: grid;
          place-content: center;
          right: 0;
          bottom: 0;
          .small-circle {
            width: 0.8rem;
            height: 0.8rem;
            background-color: #11e826;
            border-radius: 50%;
          }
        }
      }
      .personal-details {
        font-family: $AlbertSans;
        .name {
          color: $darkGrey;
          font-size: $para3;
          font-weight: 500;
        }
        .designation,
        .meassage {
          color: $lightGrey;
          font-size: $para4;
          font-weight: 500;
        }
        .meassage {
          color: $darkGrey;
          font-size: $para4;
          font-weight: 500;
        }
      }
      .pending-msg {
        background-color: $dark-bule;
        color: $white;
        width: 25px;
        height: 25px;
        padding: 1.5rem;
        border-radius: 50%;
        display: grid;
        place-content: center;
        font-size: $para4;
        font-weight: 600;
        font-family: $AlbertSans;
      }
    }
  }
}
