@import "../../../../css/variables";

.appointment-detail-screen {
  .project-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    h2 {
      font-size: $heading-small;
      color: $darkGrey;
      font-weight: 500;
      margin: 0;
    }
  }
  .appointment-detail-content {

    .project-document {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      font-size: $para2;
      font-weight: 700;
      margin-top: 0.5rem;
      margin: 2rem 0 1.5rem;
    }
    .document-section {
      border: 1px solid $haederStroke;
      border-radius: 1rem;
      padding: 2rem;
      .document-card {
        width: 15rem;
        .img {
          width: 100%;
          height: 12rem;
          border-radius: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(180deg, #f7f8fd 0%, rgba(255, 255, 255, 0) 100%);
          border: 0.5px solid rgba(255, 255, 255, 0);
          svg {
            font-size: 4rem;
          }
        }
        .name {
          font-size: $para2;
          margin: 0.5rem 0;
        }
        .date,
        .time {
          font-size: $para4;
          color: $table-text;
        }
      }
    }

  }
}
