@import "../../../../css/variables";

.add-client-screen {
  font-family: $AlbertSans;
  .add-client-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    h1 {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
    }
  }
  .upload-image {
    .image {
      width: 25rem;
      height: 25rem;
      border: 1px solid $purple;
      border-radius: 1rem;
      svg {
        font-size: 6rem;
      }
      .prev-image {
        position: relative;
        img {
          width: 100%;
          height: auto;
          border-radius: 1rem;
        }
        .remove-icon{
          position: absolute;
          right: 0;
          top: 0;
          background: transparent;
          border: none;
          width: 28px;
          height: 28px;
          margin: 0;
          cursor: pointer;
          margin: 2px 2px 0px 0px;
            svg {
              width: 100%;
              height: 100%;
          }
        }
      }
    }
    input {
      display: none;
    }
    label {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: $blue;
      font-size: $para3;
      height: 5rem;
      border-radius: 0.8rem;
      margin-top: 1.6rem;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .add-client-form {
    width: calc(100% - 25rem);
    label {
      font-size: $para3;
      font-weight: 700;
    }
    label::before{
      display: none !important;
    }
    input,
    textarea {
      padding: 1.5rem;
      width: 100%;
      border: 1px solid $haederStroke !important;
      font-size: $para2;
    }
    .country-item-input{
      .ant-form-item-explain-error{
        margin-top: 18px;
        margin-bottom: 8px;
      }
      input{
        border: none !important;
      }
    }
    .ant-select-selector,
    .ant-picker {
      height: 5.6rem;
      box-shadow: none;
      border-color: $haederStroke !important;
      align-items: center;
      width: 100%;
    }
    .ant-picker-input input {
      border: none;
      padding: 1.5rem 0.5rem;
    }
    .ant-upload-select {
      width: 100%;
      border: 1px solid $haederStroke;
      height: 5.6rem;
      border-radius: 0.6rem;
      .ant-upload {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 1.5rem;
        svg {
          font-size: 2rem;
          color: $blue;
          margin-right: 2rem;
        }
      }
    }
    .part {
      > div {
        width: 33%;
      }
    }
    h4 {
      font-size: $para2;
      font-weight: 700;
      padding-bottom: 1rem;
      border-bottom: 1px solid $haederStroke;
      margin: 2rem 0 1rem;
    }
    .add-member-footer-btn {
      margin-top: 6rem;
      button {
        width: 50%;
        height: 4.8rem;
        font-size: $para3;
        font-weight: 600;
        background-color: $blue;
        border-radius: 0.6rem;
      }
      .cancel-btn {
        background-color: transparent;
        color: $blue;
        border-color: $blue;
      }
    }
  }
}

@media (max-width: 450px) {
  .add-client-screen {
    .add-client-form {
      .part {
        flex-wrap: wrap;
        gap: 0 !important;
        > div {
          width: 100%;
        }
      }
    }
  }
}
