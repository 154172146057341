@import "../../../../css/variables";

.project-detail-member {
  .total-document {
    background-color: $headerBg;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    h4 {
      font-size: $para2;
      font-weight: 700;
      margin: 0;
    }
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0 1.5rem;
      background-color: $white;
      color: $blue;
      border-color: $blue;
      font-size: $para3;
      height: 4rem;
    }
  }
  .member-table {
    border: 1px solid $haederStroke;
    border-radius: 1rem;
    .table {
      border-radius: 1rem;
      overflow: hidden;
      margin: 0;
      thead {
        border: 0;
        th {
          border: 0;
          padding: 1rem;
          background: $dark-bule;
          color: $white;
          font-size: $para2;
          font-weight: 500;
          text-wrap: nowrap;
        }
      }
      tbody {
        vertical-align: middle;
        td {
          font-size: $para2;
          font-weight: 500;
          color: $table-text;
          padding: 1rem;
          text-wrap: nowrap;
        }
        .client-details {
          display: flex;
          align-items: center;
          .client-avatar {
            width: 5rem;
            height: 5rem;
            display: grid;
            place-content: center;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 1rem;
            img {
              width: 100%;
            }
          }
          .detail {
            font-size: $para2;
            color: $black;
            .email {
              font-size: $para3;
              color: $table-text;
              line-height: 1.8rem;
            }
          }
        }
      }
    }
  }
  .member-assigned-task {
    border: 1px solid $haederStroke;
    border-radius: 1rem;
    .header {
      padding: 1.5rem;
      border-bottom: 1px solid $haederStroke;
      .client-avatar {
        width: 5rem;
        height: 5rem;
        display: grid;
        place-content: center;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1rem;
        img {
          width: 100%;
        }
      }
      .name {
        font-size: $para2;
        font-weight: 700;
      }
    }
    .content {
      padding: 1.5rem;
      li {
        &::marker {
          font-size: $para2;
        }
        h2 {
          font-size: $para2;
        }
        p {
          font-size: $para3;
          color: $textLightColor;
        }
        .due-date {
          font-size: $para3;
          margin-bottom: 1rem;
          span {
            color: $textLightColor;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .project-detail-member {
    .member-table {
      .table {
        thead {
          th {
            font-size: $para3;
          }
        }
        tbody {
          td {
            font-size: $para3;
          }
          .client-details {
            .client-avatar {
              width: 3.5rem;
              height: 3.5rem;
            }
            .detail {
              font-size: $para3;
              .email {
                font-size: $para4;
                line-height: 1.5rem;
              }
            }
          }
        }
      }
    }
    .member-assigned-task {
      .header {
        padding: 1rem 1.5rem;
        .client-avatar {
          width: 3.5rem;
          height: 3.5rem;
        }
      }
      .content {
        padding: 1rem;
        li {
          p {
            font-size: $para4;
            margin: 0;
          }
          .due-date {
            margin-bottom: 1rem;
            span {
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}
