@import "../../../css/variables";

header {
  background-color: $headerBg;
  border-bottom: 1px solid $purple;
  height: 8.5rem;
  .admin-header-section {
    display: flex;
    height: 100%;
    .header-main {
      width: 100%;
      padding: 2rem;
      border-right: 1px solid $haederStroke;
      h1 {
        font-size: $heading;
        color: $textColor;
        margin: 0;
      }
      .icon-group {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 2rem;
        img {
          cursor: pointer;
        }
      }
      .sidebar-icon {
        font-size: 3rem;
        cursor: pointer;
      }
    }
    .header-profile-box {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 2rem 1.2rem;
      .person-status {
        .person-name {
          color: $textColor;
          font-size: $para1;
          font-weight: 500;
          text-wrap: nowrap;
        }
        .stauts {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          justify-content: flex-end;
          .circle {
            width: 1.5rem;
            img {
              width: 100%;
            }
          }
          .text {
            color: $textLightColor;
            font-size: $para3;
            font-weight: 500;
          }
        }
      }
      .profile-dropdown {
        button {
          padding: 0;
          border-radius: 8rem;
          border: none;
          box-shadow: none;
          img {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            object-fit: cover;
          }
          &::after {
            display: none;
          }
        }
        svg {
          font-size: 2.5rem;
          color: black;
        }
        .dropdown-menu {
          width: 20rem;
          padding: 0;
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
          border: none;
          background-color: #f3f3f3;
          .profile-dropwon-content {
            border-bottom: 1px solid $haederStroke;
          }
          .dropdown-item {
            color: $textLightColor;
            padding: 0.5rem 2.5rem 0;
            font-size: $para2 !important;
          }
          .logout-btn {
            color: #f34c4c;
            margin-top: 1rem;
            padding: 0.5rem 2.5rem 0.5rem;
            font-size: $para2 !important;
          }
        }
      }
      .person-profile-image {
        width: 50px;
        border-radius: 50%;
        aspect-ratio: 1/1;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 100%;
        }
      }
    }
  }
}

.ant-drawer-content-wrapper {
  .notification-drawer {
    .ant-drawer-header {
      padding: 1.8rem 2.4rem;
      border-color: $haederStroke;
      .ant-drawer-close {
        display: none;
      }
      .ant-drawer-title {
        font-size: $heading;
      }
    }
    .ant-drawer-body {
      padding: 1.2rem 0;
      .nav-tabs {
        gap: 1rem;
        padding: 0 1.2rem;
      }
      .nav-link {
        border-radius: 1rem;
        font-size: $para1;
        font-weight: 500;
        color: $lightGrey;
        border: 0;
      }
      .nav-link.active {
        color: black;
        border-bottom: 1px solid $blue;
        border-radius: 0;
      }
      .notification-body {
        padding: 1.2rem;
        .notification-card {
          padding: 1rem;
          background-color: $headerBg;
          margin-bottom: 1rem;
          border-radius: 0.8rem;
          .image {
            margin-right: 1rem;
            img {
              width: 5.4rem;
              height: 5.4rem;
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .content {
            h3 {
              font-size: $para1;
              margin: 0;
            }
            .time {
              font-size: $para3;
              font-weight: 700;
              color: $blue;
            }
          }
        }
      }
    }
  }
}
