@import "../../css/variables";

.transaction-table {
  thead {
    border: 0;
    th {
      border: 0;
      padding: 1rem;
      border-radius: 9px 9px 0px 0px;
      background: #fafbff;
      font-size: $para2;
      font-weight: 500;
      &:nth-child(6) {
        text-align: center;
      }
    }
  }
  tbody {
    vertical-align: middle;
    td {
      font-size: $para2;
      font-weight: 500;
      color: $table-text;
      padding: 1rem;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 18%;
      }
      &:nth-child(6) {
        width: 10%;
        text-align: center;
      }
    }
    .client-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .client-avatar {
        font-size: 29.091px;
        color: $darkGrey;
        background-color: #2bd2b4;
        width: 3.6rem;
        height: 3.6rem;
        display: grid;
        place-content: center;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .detail {
        font-size: $para2;
        color: $black;
        .email {
          font-size: $para3;
          color: $table-text;
        }
      }
    }
  }
}
