@import "../../css/variables";

.message-box {
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
  .message-box-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
    height: 6.8rem;
    border-bottom: 0.2rem solid $purple;
    .message-box-header {
      position: relative;
      .message-person-details {
        display: flex;
        align-items: center;
        gap: 1rem;
        .profile-pic {
          width: 4.4rem;
          height: 4.4rem;
          aspect-ratio: 1/1;
          display: flex;
          justify-content: center;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
          .circle {
            width: 1.6rem;
            height: 1.6rem;
            background-color: white;
            border-radius: 50%;
            position: absolute;
            display: grid;
            place-content: center;
            right: 0;
            bottom: 0;
            .small-circle {
              width: 0.8rem;
              height: 0.8rem;
              background-color: #11e826;
              border-radius: 50%;
            }
          }
        }
        .details {
          font-family: $AlbertSans;
          .name {
            font-size: $para3;
            color: $darkGrey;
            font-weight: 600;
          }
          .status {
            font-size: $para4;
            color: $lightGrey;
            font-weight: 600;
            color: #4b8d51;
          }
        }
      }
    }
    .search-section {
      width: 50%;
      .input-group {
        display: flex;
        align-items: center;
        background-color: $headerBg;
        .form-control {
          border: none;
          padding: 1.2rem 1.3rem;
          font-size: $para2;
          background-color: transparent;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          &:focus-visible {
            outline: none;
          }
        }
        .input-group-text {
          background-color: transparent !important;
          border: 0 !important;
        }
      }
    }
  }
  .message-content {
    height: 100%;
    overflow: auto;
    padding: 1rem 2rem;
    .input {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $purple;
      padding: 1rem;
      height: 50px;
      input {
        width: 100%;
        border: 0;
        font-size: $para2;
        font-weight: 400;
        &:focus {
          border: 0;
          outline: none;
        }
        &:focus-visible {
          border: 0;
          outline: none;
        }
      }
      .icon-group {
        display: flex;
        gap: 1rem;
        cursor: pointer;
      }
    }
  }
}
