@import "../../../css/variables";

.admin-layout-section {
  display: flex;
  height: 100vh;
  overflow: hidden;
  .side-bar-menu-main {
    background-color: $dark-bule;
    background-image: radial-gradient(95.87% 30.87% at 50% 59.56%, rgba(46, 91, 242, 0.6) 0%, rgba(44, 43, 113, 0) 100%);
    background-position-y: 300px;
    background-repeat: no-repeat;
    .logo {
      padding: 2.5rem;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.15);
      margin: 0;
      height: 8.5rem;
      img {
        height: 100%;
      }
    }
    .sidebar-slider {
      height: calc(100vh - 8.5rem);
      overflow-y: auto;
      padding-top: 3.3rem;
      .menu-main {
        border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
        padding: 0 2.5rem 1.5rem;
        .list-main {
          margin-bottom: 0.5rem;
          cursor: pointer;
          .list {
            display: flex;
            align-items: center;
            gap: 1rem;
            background-color: transparent;
            border-radius: 8rem;
            width: 100%;
            padding: 0 1.5rem;
            border-color: transparent;
            height: 5rem;
            .list-item-name {
              color: $white;
              font-family: $AlbertSans;
              font-size: $para2;
            }
            &:hover {
              background-color: $light-bule;
              border-radius: 8rem;
              .list-icon {
                filter: invert(99%) sepia(0%) saturate(7450%) hue-rotate(223deg) brightness(127%) contrast(89%);
              }
            }
          }
        }
      }
      .logout-main {
        padding: 0 2.2rem;
        .logout-list {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-top: 2.5rem;
          padding: 1rem 1.5rem;
          cursor: pointer;
          .logout-list-name {
            color: $white;
            font-family: $AlbertSans;
            font-size: $para2;
          }
          &:hover {
            background-color: $light-bule;
            border-radius: 8rem;
            .logout-icon {
              filter: invert(99%) sepia(0%) saturate(7450%) hue-rotate(223deg) brightness(127%) contrast(89%);
            }
          }
        }
      }
    }
  }
  .body-section {
    height: 100vh;
    // width: calc(100vw - 28rem);
    .header {
      width: 100%;
    }
    .body-container {
      width: 100%;
      overflow: auto;
      padding: 2.5rem;
      height: calc(100vh - 8.4rem);
    }
  }
}

@media (max-width: 992px) {
  .admin-layout-section {
    .body-section {
      width: 100vw;
      .body-container {
        height: calc(100vh - 5.4rem);
        padding: 2rem;
      }
    }
  }
}
