@import "../../../../css/variables";

.opportunity-table {
  .dropdown {
    float: right;
    height: 51px;
    border-bottom: 1px solid #e7e6e6;
    .dropdown-toggle {
      background-color: transparent;
      color: $black;
      font-size: $para3;
      font-weight: 600;
      &::after {
        content: url("../../../../assets/Icon/down-arrow-icon.svg");
        border: 0;
        margin-left: 1rem;
        vertical-align: 0;
      }
    }
    .droup-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      font-size: $para3;
      font-weight: 500;
      color: $darkGrey;
      input {
        width: 20px;
        height: 20px;
        &:is(:focus, :focus-visible) {
          outline: none;
          border: 0;
        }
      }
    }
    .dropdown-menu.show {
      transform: translate(-20px, 39px) !important;
      padding: 1rem;
      width: 200% !important;
    }
  }
  .pagination-main {
    display: flex;
    justify-content: flex-end;
    .ant-pagination {
      display: flex;
      align-items: center;
      .ant-pagination-options {
        display: none;
      }
      .anticon {
        color: $blue;
        font-weight: 700;
        vertical-align: 0;
      }
      .ant-pagination-item {
        font-size: $para4;
        color: $lightGrey;
        border: 0.5px solid #e7e6e6;
        border-radius: 5px;
        &:hover {
          background-color: transparent;
        }
      }
      .ant-pagination-item-active a {
        border-radius: 5px;
        background-color: $blue;
        color: $white;
      }
    }
  }
}
