@import "../../../css/variables";

.client-detail-card {
    background-color: $off-white;
    border-radius: 1rem;
    .profile-container {
      padding: 1.8rem 1.8rem 1.2rem;
      border-bottom: 1px solid $haederStroke;
      position: relative;
      gap: 2.5rem;
      .image {
        img {
          width: 16rem;
          aspect-ratio: 1/1;
          border-radius: 0.8rem;
        }
      }
      .detail {
        button {
          background-color: $blue;
          border-radius: 2.5rem;
          padding: 0.5rem 1.5rem;
          font-size: $para4;
          line-height: 1.5rem;
        }
        h2 {
          font-size: $heading-medium2;
          font-weight: 700;
          color: $textColor;
          margin: 1.5rem 0 0;
        }
        h4 {
          font-size: $para1;
          color: $textLightColor;
          margin: 0.5rem 0 0;
        }
      }
      .more-dropdown {
        width: fit-content;
        margin-left: auto;
        position: absolute;
        right: 1rem;
        button {
          padding: 0 !important;
          background-color: transparent;
          &::after {
            display: none;
          }
        }
        svg {
          font-size: 2.5rem;
          color: black;
        }
        .dropdown-menu {
          padding: 1.5rem;
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
          border: none;
          :nth-child(1) {
            color: $blue;
            font-size: $para2 !important;
          }
          :nth-child(2) {
            color: #f34c4c;
            font-size: $para2 !important;
          }
        }
      }
    }
    .profile-footer {
      > div {
        padding: 1.5rem 1rem;
      }
      h5 {
        font-size: $para3;
        font-weight: 600;
        color: $table-text;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        margin-bottom: 1rem;
        text-wrap: nowrap;
      }
      h4 {
        font-size: $heading-medium;
        font-weight: 700;
        margin: 0;
        text-wrap: nowrap;
      }
      .status {
        border-right: 1px solid $haederStroke;
      }
    }
  }