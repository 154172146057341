@import "../../../../css/variables";

.admin-message-section {
  .section-content {
    .chat-box {
      border-radius: 11px 0px 0px 11px;
      background: #fff;
      box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
      .title {
        padding: 1.5rem 2rem;
        height: 6.8rem;
        font-size: $para2;
        color: $darkGrey;
        font-weight: 500;
        span {
          background-color: #f34c4c;
          padding: 0.5rem;
          color: $white;
          border-radius: 50%;
          font-size: $para4;
          font-weight: 600;
          margin-left: 0.5rem;
        }
      }
      .other-messages {
        .person-details {
          background-color: $lightPurple;
          padding: 0.9rem;
          border-radius: 1.1rem;
          margin-bottom: 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .profile-img {
            width: 6.5rem;
            height: 6.5rem;
            display: flex;
            justify-content: center;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 1.6rem;
            }
            .circle {
              width: 1.6rem;
              height: 1.6rem;
              background-color: white;
              border-radius: 50%;
              position: absolute;
              display: grid;
              place-content: center;
              right: 0;
              bottom: 0;
              .small-circle {
                width: 0.8rem;
                height: 0.8rem;
                background-color: #11e826;
                border-radius: 50%;
              }
            }
          }
          .personal-details {
            font-family: $AlbertSans;
            .name {
              color: $darkGrey;
              font-size: $para3;
              font-weight: 500;
            }
            .designation,
            .meassage {
              color: $lightGrey;
              font-size: $para4;
              font-weight: 500;
            }
            .meassage {
              color: $darkGrey;
              font-size: $para4;
              font-weight: 500;
            }
          }
          .pending-msg {
            background-color: $dark-bule;
            color: $white;
            width: 25px;
            height: 25px;
            padding: 1.5rem;
            border-radius: 50%;
            display: grid;
            place-content: center;
            font-size: $para4;
            font-weight: 600;
            font-family: $AlbertSans;
          }
        }
      }
    }
    .message-box {
      background: #fff;
      box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
      border-radius: 0 11px 11px 0;
      .message-box-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 2rem;
        height: 6.8rem;
        border-bottom: 0.2rem solid $purple;
        .message-box-header {
          position: relative;
          .message-person-details {
            display: flex;
            align-items: center;
            gap: 1rem;
            .profile-pic {
              width: 4.4rem;
              height: 4.4rem;
              aspect-ratio: 1/1;
              display: flex;
              justify-content: center;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
              .circle {
                width: 1.6rem;
                height: 1.6rem;
                background-color: white;
                border-radius: 50%;
                position: absolute;
                display: grid;
                place-content: center;
                right: 0;
                bottom: 0;
                .small-circle {
                  width: 0.8rem;
                  height: 0.8rem;
                  background-color: #11e826;
                  border-radius: 50%;
                }
              }
            }
            .details {
              font-family: $AlbertSans;
              .name {
                font-size: $para3;
                color: $darkGrey;
                font-weight: 600;
              }
              .status {
                font-size: $para4;
                color: $lightGrey;
                font-weight: 600;
                color: #4b8d51;
              }
            }
          }
        }
        .search-section {
          width: 50%;
          .input-group {
            display: flex;
            align-items: center;
            background-color: $headerBg;
            .form-control {
              border: none;
              padding: 1.2rem 1.3rem;
              font-size: $para2;
              background-color: transparent;
              &:focus {
                outline: none;
                box-shadow: none;
              }
              &:focus-visible {
                outline: none;
              }
            }
            .input-group-text {
              background-color: transparent !important;
              border: 0 !important;
            }
          }
        }
      }
      .message-content {
        height: 100%;
        overflow: auto;
        padding: 1rem 2rem;
        .input {
          display: flex;
          justify-content: space-between;
          border-top: 1px solid $purple;
          padding: 1rem;
          height: 50px;
          input {
            width: 100%;
            border: 0;
            font-size: $para2;
            font-weight: 400;
            &:focus {
              border: 0;
              outline: none;
            }
            &:focus-visible {
              border: 0;
              outline: none;
            }
          }
          .icon-group {
            display: flex;
            gap: 1rem;
            cursor: pointer;
          }
        }
      }
    }

    .chat-analyze {
      background-color: $lightPurple;
      border-radius: 0px 11px 11px 0px;
      box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
      .chat-analyze-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 2rem;
        height: 6.8rem;
        border-bottom: 0.2rem solid #d8dbf3;
        .heading {
          font-size: $para2;
          color: $darkGrey;
          font-weight: 700;
          font-family: $AlbertSans;
        }
        .thread-btn {
          Button {
            padding: 0.8rem 2.5rem;
            border-radius: 8rem;
            font-size: $para3;
            font-weight: 600;
            font-family: $AlbertSans;
          }
        }
      }
      .chat-analyze-content {
        margin-top: 1rem;
        padding: 0rem 1rem;
        .accordion {
          .accordion-item {
            margin-bottom: 1.6rem;
            border-radius: 1rem;
            border: 0;
            .accordion-button:not(.collapsed) {
              background-color: transparent;
              border: 0;
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
            .accordion-button {
              border-radius: 1rem;
              font-size: $para2;
              font-weight: 700;
              color: $black;
              border: 0;
              font-family: $AlbertSans;
              &:focus {
                outline: none;
                box-shadow: none;
              }
            }
            .tags-main {
              display: flex;
              flex-wrap: wrap;
              gap: 0.8rem;
              .tags {
                display: flex;
                background-color: $skyBlue;
                padding: 0.5rem;
                border-radius: 0.8rem;
                gap: 0.5rem;
                font-family: $AlbertSans;
                .tag-name {
                  font-size: $para3;
                  color: $black;
                  font-weight: 500;
                }
                .count {
                  background-color: $dark-bule;
                  color: $white;
                  padding: 0.2rem 0.5rem;
                  border-radius: 0.4rem;
                  width: 22px;
                  height: 22px;
                  display: grid;
                  place-content: center;
                  font-size: $para4;
                  font-weight: 700;
                }
              }
            }
            .sentimentals-tag-main {
              display: flex;
              flex-wrap: wrap;
              gap: 0.8rem;
              .tags {
                display: flex;
                padding: 0.5rem 0.9rem;
                border-radius: 0.8rem;
                gap: 0.5rem;
                align-items: center;
              }
              .name {
                font-size: $para3;
                color: $black;
                font-weight: 500;
              }
              .per-cent {
                color: $black;
                font-size: $para4;
                font-weight: 700;
              }
            }
            .speakers-tag-main {
              .table {
                .thead {
                  display: flex;
                  justify-content: space-between;
                  gap: 1rem;
                  margin-bottom: 0.5rem;
                  .th {
                    font-size: $para4;
                    color: $lightGrey;
                    font-weight: 500;
                    font-family: $AlbertSans;
                  }
                }
                .tbody {
                  display: flex;
                  justify-content: space-between;
                  background-color: $lightPurple;
                  margin-bottom: 1rem;
                  border-radius: 0.8rem;
                  .td {
                    padding: 0.8rem;
                    color: $darkGrey;
                    font-weight: 500;
                    font-size: $para3;
                    font-family: $AlbertSans;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
