@import "../../../css/variables";
.result-previwe-modal {
  .modal-header {
    background-color: $dark-bule;
    color: $white;
    font-size: $heading-medium;
    font-family: $AlbertSans;
    font-weight: 600;
    border-bottom: 0 !important;
    padding: 1.7rem 2.6rem;
    .btn-close {
      width: 2.4rem;
      height: 2.4rem;
      background: transparent var(--bs-btn-close-bg) center/15px auto no-repeat;
      filter: invert(100%) sepia(11%) saturate(388%) hue-rotate(270deg) brightness(119%) contrast(100%);
    }
  }
  .modal-body {
    height: 80vh;
    padding: 1.7rem 2.6rem;
    font-size: $para2;
    color: $darkGrey;
    font-family: $AlbertSans;
    font-weight: 400;
    line-height: 150%;
    overflow: auto;

    .owner-company-details,
    .client-company-details,
    .subject {
      margin-bottom: 2rem;
    }
    .result-points {
      margin-left: 1rem;
      p {
        margin: 0;
        span {
          font-weight: 700;
        }
        .sub-points {
          ol {
            margin-bottom: 0;
            li {
              list-style: circle;
            }
          }
        }
      }
    }
  }
  .modal-footer {
    background-color: #f7f7f7;
    border: 0;
    height: 80px;
    padding: 1.7rem 2.6rem;
    font-family: $AlbertSans;
    .regenerate-btn {
      border: 2px solid $blue;
      background-color: $white;
      color: $blue;
      border-radius: 8rem;
      font-size: $para1;
      padding: 0.5rem 1rem;
      font-weight: 600;
      margin-right: 2rem;
    }
    .sve-btn {
      background-color: $blue;
      color: $white;
      border-radius: 8rem;
      font-size: $para1;
      padding: 0.8rem 1.5rem;
      font-weight: 600;
    }
  }
}
