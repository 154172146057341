@import "../../css/variables";

.opportunity-table {
  thead {
    border: 0;
    th {
      border: 0;
      padding: 1rem !important;
      border-radius: 9px 9px 0px 0px;
      background: #fafbff !important;
      font-size: $para2;
      font-weight: 500;
      text-wrap: nowrap;
    }
  }
  tbody {
    td {
      font-size: $para2;
      font-weight: 500;
      padding: 1rem 1rem;
      color: $table-text;
      text-wrap: nowrap;
    }
    select {
      border: 0;
      font-size: $para3;
      font-weight: 600;
      width: 100px;
      &:is(:focus, :focus-visible) {
        outline: 0;
        box-shadow: none;
      }
      .new {
        color: $green;
        font-weight: 600;
      }
      .follow-up {
        color: $bluis;
        font-weight: 600;
      }
      .sold {
        color: $red;
        font-weight: 600;
      }
      .not-relevant {
        color: $textColor;
        font-weight: 600;
      }
    }
  }
}
