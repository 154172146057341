@import "../../../css/variables";

.payment-modal-main {
  font-family: $AlbertSans;

  .modal-content {
    margin: 0 auto;
    border: 0;

    .modal-body {
      padding: 4rem;

      .heading {
        font-size: $heading-small;
        color: $textColor;
        font-weight: 700;
        margin-bottom: 2rem;
      }

      .transaction-details {
        .transcation {
          display: flex;
          align-items: center;
          gap: 2rem;
          margin: 1rem 0rem;

          .tag {
            width: 50%;
            color: $lightGrey;
            font-size: $para2;
            font-weight: 500;
          }

          .text {
            color: $heading-small;
            font-size: $para2;
            font-weight: 500;
          }
        }
      }

      .ok-btn {
        display: flex;
        justify-content: center;
        margin-top: 4rem;

        Button {
          width: 50%;
          padding: 1rem;
          font-size: $para3;
          background-color: $dark-bule;
          font-weight: 600;
          border-radius: .8rem;
          border: 0;
        }
      }
    }
  }
}