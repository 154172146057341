@import "variables";

html {
  font-size: 62.5%;
  font-family: $AlbertSans;
}

body {
  margin: 0;
  font-family: $AlbertSans;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app {
  text-align: center;
  width: 100%;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-error {
  color: #f45151;
  font-size: $para2;
  margin-top: 0.25rem;
  margin-bottom: 0;
  padding-left: 0;
}

.margin-top-20{
margin-top: 20px;
}

.dropdown {
  .dropdown-toggle {
    font-size: $para3;
    padding: 0.8rem 2rem;
    border-radius: 8rem;
    border: none;
    .bnt {
      font-size: $para3 !important;
    }
    &::after {
      font-size: 14px;
      margin-left: 3rem;
    }
    &:active {
      border: none;
    }
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 0.8rem;
    font-size: $para3;
    color: $black;
    font-weight: 600;
    font-family: $AlbertSans;
    margin-bottom: 0.8rem;
    &:active {
      background-color: transparent;
    }
  }
}

.row {
  margin: 0;
}

.ant-form {
  .ant-form-item-row {
    display: block;
    .ant-form-item-label {
      text-align: left;
      padding: 0;
      label {
        font-family: $AlbertSans;
        &::after {
          display: none;
        }
      }
    }
  }
  input,
  textarea {
    font-family: $AlbertSans;
    &:hover {
      border-color: $haederStroke;
    }
    &:focus {
      box-shadow: none;
      border-color: black;
    }
  }
  .ant-select:hover .ant-select-selector {
    border-color: $haederStroke !important;
  }
  .ant-select-focused > .ant-select-selector {
    box-shadow: none !important;
    border-color: $haederStroke !important;
  }
}

.ant-drawer {
  outline: none;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $lightPurple;
  border-radius: 1rem;
}
::-webkit-scrollbar {
  width: 6px;
  background-color: $lightPurple;
  border-radius: 1rem;
  height: 0.8rem;
}

::-webkit-scrollbar-thumb {
  background-color: #100d3b;
  border-radius: 1rem;
}

.table {
  thead {
    position: sticky;
    top: 0;
  }
}
