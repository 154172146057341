@import "../../css/variables";

header {
  background-color: $headerBg;
  border-bottom: 1px solid $purple;
  height: 8.5rem;
  .header-main-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .mobile-logo {
      height: 4.5rem;
      width: 30rem;
      display: flex;
      align-items: center;
      padding-left: 2rem;
      img {
        height: 3.4rem;
      }
    }
    .sidebar-icon {
      font-size: 3rem;
      cursor: pointer;
    }
    .icon-group {
      width: 100%;
      height: 100%;
      padding: 2rem;
      border-right: 1px solid $haederStroke;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 2rem;
      img {
        cursor: pointer;
      }
    }
    .header-ai-box {
      padding: 2rem 1.5rem;
      display: flex;
      align-items: center;
      gap: 1.2rem;
      border-right: 1px solid $haederStroke;
      cursor: pointer;
      .credits {
        font-size: $para4;
        color: $textLightColor;
        font-weight: 500;
      }
      .text {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        span {
          color: $textColor;
          font-size: $para1;
          font-weight: 500;
          opacity: 0.8;
        }
        button {
          background-color: transparent;
          color: $blue;
          border-radius: 0.8rem;
          font-size: 1rem;
          width: 6rem;
          font-weight: 500;
          border: 1px solid $blue;
          padding: 0.2rem 0.5rem;
        }
      }
    }
    .header-profile-box {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      padding: 2rem 1.2rem;
      .person-status {
        .person-name {
          color: $textColor;
          font-size: $para1;
          font-weight: 500;
          text-wrap: nowrap;
        }
        .stauts {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          justify-content: flex-end;
          .circle {
            width: 1.5rem;
            img {
              width: 100%;
            }
          }
          .text {
            color: $textLightColor;
            font-size: $para3;
            font-weight: 500;
          }
        }
      }
      .profile-dropdown {
        button {
          padding: 0;
          border-radius: 8rem;
          border: none;
          box-shadow: none;
          img {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
            object-fit: cover;
          }
          &::after {
            display: none;
          }
        }
        svg {
          font-size: 2.5rem;
          color: black;
        }
        .dropdown-menu {
          width: 20rem;
          padding: 0;
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
          border: none;
          background-color: #f3f3f3;
          .profile-dropwon-content {
            border-bottom: 1px solid $haederStroke;
          }
          .dropdown-item {
            color: $textLightColor;
            padding: 0.5rem 2.5rem 0;
            font-size: $para2 !important;
          }
          .logout-btn {
            color: #f34c4c;
            margin-top: 1rem;
            padding: 0.5rem 2.5rem 0.5rem;
            font-size: $para2 !important;
          }
        }
      }
      .person-profile-image {
        width: 50px;
        border-radius: 50%;
        aspect-ratio: 1/1;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 100%;
        }
      }
    }
  }
}

.sidebar-drawer {
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    // overflow: hidden;
    background-color: $dark-bule;
    padding: 0;
  }
  .logo {
    padding: 2.5rem;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.15);
    margin: 0;
    height: 5.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      height: 3.4rem;
    }
    svg {
      color: white;
      font-size: 2.5rem;
      cursor: pointer;
    }
  }
  .sidebar-slider {
    min-height: 100vh;
    height: auto;
    overflow-y: auto;
    padding-top: 1rem;
    .menu-main {
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.4);
      padding: 0 2rem 1.5rem;
      .list-main {
        margin-bottom: 0.5rem;
        cursor: pointer;
        .list {
          display: flex;
          align-items: center;
          gap: 1rem;
          background-color: transparent;
          border-radius: 8rem;
          width: 100%;
          padding: 0 1.5rem;
          border-color: transparent;
          height: 5rem;
          .list-item-name {
            color: $white;
            font-family: $AlbertSans;
            font-size: $para2;
          }
          &:hover {
            background-color: $light-bule;
            border-radius: 8rem;
            .list-icon {
              filter: invert(99%) sepia(0%) saturate(7450%) hue-rotate(223deg) brightness(127%) contrast(89%);
            }
          }
        }
      }
    }
    .logout-main {
      padding: 0 2.2rem;
      .logout-list {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 2.5rem;
        padding: 1rem 1.5rem;
        cursor: pointer;
        .logout-list-name {
          color: $white;
          font-family: $AlbertSans;
          font-size: $para2;
        }
        &:hover {
          background-color: $light-bule;
          border-radius: 8rem;
          .logout-icon {
            filter: invert(99%) sepia(0%) saturate(7450%) hue-rotate(223deg) brightness(127%) contrast(89%);
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  header {
    height: 5.4rem !important;
  }
  .header-profile-box-mobile {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 2rem 1.2rem 1rem 3.8rem;
    .person-status {
      .person-name {
        color: $white;
        font-family: $AlbertSans;
        font-size: $para2;
      }
      .stauts {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .circle {
          width: 1.5rem;
          img {
            width: 100%;
          }
        }
        .text {
          color: $white;
          font-family: $AlbertSans;
          font-size: $para3;
        }
      }
    }
    .profile-dropdown {
      button {
        padding: 0;
        border-radius: 8rem;
        border: none;
        box-shadow: none;
        img {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          object-fit: cover;
        }
        &::after {
          display: none;
        }
      }
      svg {
        font-size: 2.5rem;
        color: black;
      }
      .dropdown-menu {
        width: 20rem;
        padding: 0;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
        border: none;
        background-color: #f3f3f3;
        .profile-dropwon-content {
          border-bottom: 1px solid $haederStroke;
        }
        .dropdown-item {
          color: $textLightColor;
          padding: 0.5rem 2.5rem 0;
          font-size: $para2 !important;
        }
        .logout-btn {
          color: #f34c4c;
          margin-top: 1rem;
          padding: 0.5rem 2.5rem 0.5rem;
          font-size: $para2 !important;
        }
      }
    }
    .person-profile-image {
      width: 50px;
      border-radius: 50%;
      aspect-ratio: 1/1;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 100%;
      }
    }
  }
  .header-ai-box-mobile {
    padding: 1.5rem 2.5rem 1.5rem 3.8rem;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.15);
    display: flex;
    align-items: center;
    gap: 1.2rem;
    .credits,
    .text {
      color: $white;
      font-family: $AlbertSans;
      font-size: $para2;
    }
    button {
      background-color: transparent;
      color: $blue;
      border-radius: 0.8rem;
      font-size: 1rem;
      width: 6rem;
      font-weight: 500;
      border: 1px solid $blue;
      padding: 0.2rem 0.5rem;
    }
  }
}
