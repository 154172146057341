@import "../../../../css/variables";

.team-detail-screen {
  .project-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    h2 {
      font-size: $heading-small;
      color: $darkGrey;
      font-weight: 500;
      margin: 0;
    }
  }
  .team-detail-content {
    .table-heading {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      font-size: 2.5rem;
      font-weight: 700;
      margin: 3rem 0 2rem;
      span {
        font-size: $para2;
        font-weight: 500;
        margin-top: 0.5rem;
      }
    }

  }
}

@media (max-width: 1200px) and (min-width: 992px) {
  .team-detail-screen {
    .team-detail-content {
      .team-detail-card {
        .profile-experience {
          .kpi {
            border-right: none !important;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .team-detail-screen {
    .team-detail-content {
      .team-detail-card {
        .profile-container {
          .image {
            img {
              width: 100%;
              max-width: 23rem;
            }
          }
        }
        .profile-experience {
          .kpi {
            border-right: none !important;
          }
        }
      }
    }
  }
}
