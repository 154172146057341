@import "../../../css/variables";

.admin-dashboard-section {
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .heading {
      margin: 0;
      font-size: $heading-small;
      color: $textColor;
      font-weight: 500;
      font-family: $AlbertSans;
    }
    .dropdown {
      .dropdown-toggle {
        background-color: transparent;
        color: $black;
        font-size: $para3;
        font-weight: 600;
        &::after {
          content: url("../../../assets/Icon/down-arrow-icon.svg");
          border: 0;
          margin-left: 1rem;
          vertical-align: 0;
        }
      }
      .droup-item {
        margin-bottom: 1rem;
        font-size: $para3;
        font-weight: 500;
        color: $darkGrey;
      }
      .dropdown-menu.show {
        transform: translate(-20px, 39px) !important;
        padding: 1rem;
        width: 15rem !important;
      }
    }
  }
  .latest-transaction {
    font-size: $heading-small;
    margin: 1rem 0;
  }

}

@media (max-width: 768px) {
  .admin-dashboard-section {
    .overview-section {
      .part {
        width: 48%;
      }
    }
  }
}

@media (max-width: 480px) {
  .admin-dashboard-section {
    .overview-section {
      .part {
        width: 100%;
      }
    }
  }
}
