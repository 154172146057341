@import "../../../../css/variables";

.add-appointment-screen {
  font-family: $AlbertSans;
  .add-appointment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    .heading {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
    }
  }
  .add-appointment-form {
    width: 100%;
    .client-information {
      border-bottom: 1px solid $haederStroke;
      .ant-checkbox-wrapper {
        display: flex;
        font-size: $para2;
        font-weight: 700;
      }
    }
    label {
      font-size: $para3;
      font-weight: 700;
      text-wrap: nowrap;
    }
    label::before{
      display: none !important;
    }
    input,
    textarea {
      padding: 1.5rem;
      width: 100%;
      border: 1px solid $haederStroke !important;
      font-size: $para2;
    }
    // .select-date-time {
    //   border: 1px solid $haederStroke;
    //   border-radius: 0.8rem;
    // }
      .client-item-input{
        .ant-form-item-explain-error{
          margin-top: 18px;
          margin-bottom: 8px;
        }
      }
    

    .form-date-picker {
      .ant-select-selector,
      .ant-picker {
        height: 5.6rem;
        box-shadow: none;
        border-color: $haederStroke !important;
        align-items: center;
        width: 100%;
      }
      .ant-picker-input input {
        border: none !important;
        padding: 1.5rem 0.5rem;
      }
      
    }
    .select-available-time {
      h2 {
        font-size: $para2;
        margin-bottom: 2rem;
      }
      Button {
        width: 30%;
        height: 5rem;
        background-color: $blue;
        font-size: $para2;
      }
      .selected-time {
        background-color: #ffffff;
        color: $blue;
      }
    }
    .ant-select-selector,
    .ant-picker {
      height: 5.6rem;
      box-shadow: none;
      border-color: $haederStroke !important;
      align-items: center;
      width: 100%;
    }
    h4 {
      font-size: $para2;
      font-weight: 700;
      padding-bottom: 1rem;
      border-bottom: 1px solid $haederStroke;
      margin: 2rem 0 1rem;
    }
    .add-member-footer-btn {
      margin-top: 3rem;
      button {
        width: 50%;
        height: 4.8rem;
        font-size: $para3;
        font-weight: 600;
        background-color: $blue;
        border-radius: 0.6rem;
      }
      .cancel-btn {
        background-color: transparent;
        color: $blue;
        border-color: $blue;
      }
    }
  }
}

@media (max-width: 576px) {
  .add-appointment-screen {
    .add-appointment-header {
      .heading {
        font-size: 3rem !important;
      }
    }
  }
}
