@import "../../../css/variables";

.agency-screen {
  height: 90%;
  .list-table {
    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      .heading {
        h2 {
          font-size: $heading-small;
          font-weight: 600;
          color: $darkGrey;
        }
        h3 {
          font-size: $para2;
          color: $lightGrey;
          font-weight: 500;
          span {
            color: $black;
          }
        }
      }
      .search-section {
        width: 30%;
        .input-group {
          width: 100%;
          display: flex;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #e7e6e6;
          .form-control {
            border: 0;
            padding: 1rem;
            font-size: $para2;
            color: #9999a8;
            font-weight: 400;
            background-color: transparent;
            &:focus {
              outline: none;
              box-shadow: none;
            }
            &:focus-visible {
              outline: none;
            }
          }
          .input-group-text {
            background-color: transparent !important;
            border: 0 !important;
          }
        }
      }
      .dropdown {
        .dropdown-toggle {
          background-color: transparent;
          color: $black;
          font-size: $para3;
          font-weight: 600;

          &::after {
            content: url("../../../assets/Icon/down-arrow-icon.svg");
            border: 0;
            margin-left: 1rem;
            vertical-align: 0;
          }
        }
        .droup-item {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1rem;
          font-size: $para3;
          font-weight: 500;
          color: $darkGrey;
          input {
            width: 20px;
            height: 20px;

            &:is(:focus, :focus-visible) {
              outline: none;
              border: 0;
            }
          }
        }
        .dropdown-menu.show {
          transform: translate(-20px, 39px) !important;
          padding: 1rem;
          width: 100% !important;
        }
      }
    }
    .table {
      thead {
        border: 0;
        th {
          border: 0;
          padding: 1rem;
          border-radius: 9px 9px 0px 0px;
          background: #fafbff;
          font-size: $para2;
          font-weight: 500;
          text-wrap: nowrap;
          &:nth-child(7) {
            text-align: center;
          }
        }
      }
      tbody {
        vertical-align: middle;
        td {
          font-size: $para2;
          font-weight: 500;
          color: $textLightColor;
          padding: 1rem;
          text-wrap: nowrap;
          &:nth-child(1),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 14%;
          }
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(7) {
            width: 10%;
          }
        }
        .client-details {
          display: flex;
          align-items: center;
          gap: 1rem;
          .client-avatar {
            font-size: 29.091px;
            color: $darkGrey;
            background-color: #2bd2b4;
            width: 3.6rem;
            height: 3.6rem;
            display: grid;
            place-content: center;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 100%;
            }
          }
          .name {
            color: $textColor;
          }
        }
        .icons {
          gap: 2rem;
          font-size: 2rem;
          svg {
            cursor: pointer;
            &:nth-child(1) {
              color: $blue;
            }
            &:nth-child(2) {
              color: #f34c4c;
            }
          }
        }
      }
    }
  }
  .pagination-main {
    display: flex;
    justify-content: flex-end;
    .ant-pagination {
      display: flex;
      align-items: center;
      .ant-pagination-options {
        display: none;
      }
      .anticon {
        color: $blue;
        font-weight: 700;
        vertical-align: 0;
      }
      .ant-pagination-item {
        font-size: $para4;
        color: $lightGrey;
        border: 0.5px solid #e7e6e6;
        border-radius: 5px;
        &:hover {
          background-color: transparent;
        }
      }
      .ant-pagination-item-active a {
        border-radius: 5px;
        background-color: $blue;
        color: $white;
      }
    }
  }
}

.delete-modal {
  .modal-content {
    border: 0;
    .modal-body {
      border: 0;
      padding: 2.5rem;
      h2 {
        font-size: $heading;
        color: #f34c4c;
        text-align: center;
        margin-bottom: 2rem;
      }
      .confirmation {
        font-size: $para2;
        text-align: center;
        width: 60%;
        margin: 0 auto 3rem;
      }
      .footer-btn {
        gap: 2rem;
        button {
          width: 50%;
          height: 4.8rem;
          font-size: $para3;
          font-weight: 600;
          background-color: #f34c4c;
          border-radius: 0.6rem;
          border-color: transparent;
          box-shadow: none;
        }
        .cancel-btn {
          background-color: transparent;
          color: $lightGrey;
          border-color: $lightGrey;
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .agency-screen {
    .list-table {
      .table-header {
        .heading {
          h2 {
            font-size: $para1;
          }
          h3 {
            font-size: $para3;
          }
        }
      }
    }
  }
}
