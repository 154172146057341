@import "../../../../css/variables";

.opportunity-detail-screen {
  height: 90%;

  .opportunity-header {
    margin-bottom: 1rem;
    .project-heading {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      h2 {
        font-size: $heading-small;
        color: $darkGrey;
        font-weight: 500;
        margin: 0;
      }
    }
    .current-stage {
      border: 1px solid $textColor;
      border-radius: 1rem;
      .part {
        padding: 0.5rem 1.5rem;
        &:nth-child(1) {
          border-right: 1px solid $textColor;
        }
        h4 {
          font-size: $para3;
          margin: 0;
          color: $textLightColor;
        }
        h3 {
          font-size: $para2;
          font-weight: 600;
          margin: 0;
        }
      }
      .new {
        color: #159900;
      }
    }
  }

  .footer-btn {
    button {
      font-size: $para3;
      padding: 1.2rem 1.6rem;
      border-radius: 0.8rem;
      border: none;
    }
    .btn-groups {
      gap: 1.5rem;
      button {
        background-color: $blue;
      }
    }
    .chat-btn {
      background-color: $dark-bule;
      svg {
        color: white;
        margin-right: 0.5rem;
      }
    }
    .more-dropdown {
      border: 1px solid $blue;
      border-radius: 0.8rem;
      button {
        background-color: $white;
        color: $blue;
        height: 100%;
        &::after {
          color: $blue;
        }
      }
      svg {
        font-size: 2.5rem;
        color: black;
      }
      .dropdown-menu {
        padding: 1.5rem;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
        border: none;
        width: 100%;
        .dropdown-item {
          font-size: $para2 !important;
          padding: 0.2rem 0;
          margin-bottom: 0.5rem;
        }
        :nth-child(3) {
          color: $green;
        }
        :nth-child(1) {
          color: $blue;
        }
        :nth-child(2) {
          color: $yellow;
        }
        :nth-child(4) {
          color: $red;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .opportunity-detail-screen {
    .opportunity-detail-content {
      .detail {
        width: 100%;
      }
    }
  }
}
