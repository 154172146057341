@import "../../../css/variables";

.assign-project-table {
  thead {
    border: 0;
    th {
      border: 0;
      padding: 1rem !important;
      border-radius: 9px 9px 0px 0px;
      background: #fafbff !important;
      font-size: $para3;
      font-weight: 500;
      &:nth-child(5),
      &:nth-child(6) {
        text-align: center;
      }
    }
  }
  tbody {
    td {
      font-size: $para3;
      font-weight: 500;
      padding: 1rem;
      color: $table-text !important;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: 18%;
      }
      &:nth-child(4) {
        width: 18%;
      }
      &:nth-child(5) {
        width: 18%;
        text-align: center;
      }
      &:nth-child(6) {
        width: 10%;
        text-align: center;
      }
    }
    .progress-bar {
      border-radius: 10px;
    }
    .completed {
      border-radius: 3rem;
      background: #f5f6ff;
      padding: 0.5rem 1rem;
      color: $blue;
      font-size: $para2;
      font-weight: 500;
      span {
        margin-right: 1rem;
      }
    }
    .assign-btn {
      font-size: $para4;
      width: 7rem;
      color: $blue;
      background-color: $white;
    }
  }
}
