$dark-bule: #100d3b;
$white: #ffffff;
$light-bule: #28264f;
$haederStroke: #d8dbf3;
$headerBg: #fafbff;
$textColor: #161a29;
$textLightColor: #5e5e7a;
$darkGrey: #494c54;
$activeStauts: #31d07d;
$green: #18ab00;
$bluis: #4f7bff;
$red: #ec0808;
$yellow: #d2cb2b;
$lightPurple: #e6e5fd;
$purple: #d8dbf3;
$blue: #2b4ed2;
$lightGrey: #95979c;
$skyBlue: #e8ecf7;
$black: #2b2b2b;
$table-text: #9999a8;
$off-white: #f5f6ff;
$AlbertSans: "Albert Sans", sans-serif;
$para1: 1.8rem;
$para2: 1.6rem;
$para3: 1.4rem;
$para4: 1.2rem;
$heading-small: 2rem;
$heading-medium: 2.2rem;
$heading-medium2: 2.8rem;
$heading: 3.2rem;
$heading-large: 3.5rem;
