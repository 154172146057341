@import "../../../css/variables";

.message-section-main {
  .section-header {
    margin-bottom: 3rem;
    .heading {
      width: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
      img {
        width: 3.5rem;
        height: 3.5rem;
      }
    }
  }

  .section-content {
    .chat-box {
      border-radius: 11px 0px 0px 11px;
      background: #fff;
      box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
    }
  }
}
