@import "../../css/variables";

.proposal-table {
  height: 50%;
  thead {
    border: 0;
    th {
      border: 0;
      padding: 1rem;
      border-radius: 9px 9px 0px 0px;
      background: #fafbff;
      font-size: $para2;
      font-weight: 500;
      text-wrap: nowrap;
    }
  }
  tbody {
    td {
      font-size: $para2;
      font-weight: 500;
      padding: 0.5rem 1rem;
      color: $table-text;
      text-wrap: nowrap;
    }
    .pending {
      color: $darkGrey;
    }
    .approved {
      color: $green;
    }
    .reject {
      color: $red;
    }
    .client-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .client-avatar {
        font-size: 29.091px;
        color: $darkGrey;
        background-color: #2bd2b4;
        width: 3.6rem;
        height: 3.6rem;
        display: grid;
        place-content: center;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
    .created {
      .creater-name {
        font-size: $para2;
        color: $darkGrey;
        font-weight: 500;
      }
      .desg {
        font-size: $para4;
        font-weight: 400;
        margin-top: -0.2rem;
      }
    }
    .date,
    .time {
      font-size: $para2;
      font-weight: 500;
    }
    .time {
      margin-top: -0.2rem;
    }
  }
}
