@import "../../../css/variables";

.plan-screen {
  height: 90%;
  .list-table {
    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      .heading {
        h2 {
          font-size: $heading-small;
          font-weight: 600;
          color: $darkGrey;
        }
        h3 {
          font-size: $para2;
          color: $lightGrey;
          font-weight: 500;
          span {
            color: $black;
          }
        }
      }
      .search-section {
        width: 30%;
        .input-group {
          width: 100%;
          display: flex;
          align-items: center;
          border-radius: 8px;
          border: 1px solid #e7e6e6;
          .form-control {
            border: 0;
            padding: 1rem;
            font-size: $para2;
            color: #9999a8;
            font-weight: 400;
            background-color: transparent;
            &:focus {
              outline: none;
              box-shadow: none;
            }
            &:focus-visible {
              outline: none;
            }
          }
          .input-group-text {
            background-color: transparent !important;
            border: 0 !important;
          }
        }
      }
      .create-plan-btn {
        background-color: $blue;
        padding: 1rem 1rem;
        font-size: $para2;
        border-radius: 0.8rem;
        svg {
          color: $white;
          font-size: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }
    .table {
      thead {
        border: 0;
        th {
          border: 0;
          padding: 1rem;
          border-radius: 9px 9px 0px 0px;
          background: #fafbff;
          font-size: $para2;
          font-weight: 500;
          &:nth-child(9) {
            text-align: center;
          }
        }
      }
      tbody {
        vertical-align: middle;
        td {
          font-size: $para2;
          font-weight: 500;
          color: $textLightColor;
          padding: 1rem;
          width: 11%;
          &:nth-child(1),
          &:nth-child(2) {
            color: $textColor;
            font-weight: 700;
          }
        }
        .icons {
          gap: 2rem;
          font-size: 2rem;
          svg {
            cursor: pointer;
            &:nth-child(1) {
              color: $blue;
            }
            &:nth-child(2) {
              color: #f34c4c;
            }
          }
          .input-switch {
            input {
              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
  .pagination-main {
    display: flex;
    justify-content: flex-end;
    .ant-pagination {
      display: flex;
      align-items: center;
      .ant-pagination-options {
        display: none;
      }
      .anticon {
        color: $blue;
        font-weight: 700;
        vertical-align: 0;
      }
      .ant-pagination-item {
        font-size: $para4;
        color: $lightGrey;
        border: 0.5px solid #e7e6e6;
        border-radius: 5px;
        &:hover {
          background-color: transparent;
        }
      }
      .ant-pagination-item-active a {
        border-radius: 5px;
        background-color: $blue;
        color: $white;
      }
    }
  }
}

.delete-modal {
  .modal-content {
    border: 0;
    .modal-body {
      border: 0;
      padding: 2.5rem;
      h2 {
        font-size: $heading;
        color: #f34c4c;
        text-align: center;
        margin-bottom: 2rem;
      }
      .confirmation {
        font-size: $para2;
        text-align: center;
        width: 60%;
        margin: 0 auto 3rem;
      }
      .footer-btn {
        gap: 2rem;
        button {
          width: 50%;
          height: 4.8rem;
          font-size: $para3;
          font-weight: 600;
          background-color: #f34c4c;
          border-radius: 0.6rem;
          border-color: transparent;
          box-shadow: none;
        }
        .cancel-btn {
          background-color: transparent;
          color: $lightGrey;
          border-color: $lightGrey;
        }
      }
    }
  }
}
