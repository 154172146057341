@import "../../../css/variables";

.create-plan-screen {
  font-family: $AlbertSans;
  .title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.2rem;
    h2 {
      font-size: $heading-small;
      color: $darkGrey;
      font-weight: 500;
      margin: 0;
    }
  }
  .create-plan-form {
    width: 100%;
    label {
      font-size: $para3;
      font-weight: 700;
    }
    input,
    textarea {
      padding: 1.5rem;
      width: 100%;
      border: 1px solid $haederStroke;
      font-size: $para2;
    }
    .ant-select-selector,
    .ant-picker {
      height: 5.6rem;
      box-shadow: none;
      border-color: $haederStroke;
      align-items: center;
      width: 100%;
    }
    .ant-picker-input input {
      border: none;
      padding: 1.5rem 0.5rem;
    }
    .ant-upload-select {
      width: 100%;
      border: 1px solid $haederStroke;
      height: 5.6rem;
      border-radius: 0.6rem;
      .ant-upload {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 1.5rem;
        svg {
          font-size: 2rem;
          color: $blue;
          margin-right: 2rem;
        }
      }
    }
    .part {
      > div {
        width: 33%;
      }
    }
    h4 {
      font-size: $para2;
      font-weight: 700;
      padding-bottom: 1rem;
      border-bottom: 1px solid $haederStroke;
      margin: 2rem 0 1rem;
    }
    .add-btn {
      background-color: transparent;
      border: none;
      color: $blue;
      font-size: $para2;
      display: flex;
      gap: 1.5rem;
      padding: 0;
      cursor: pointer;
      margin: 0.5rem 0 3rem;
    }
  }
  .add-member-footer-btn {
    margin-top: 1rem;
    button {
      width: 14rem;
      height: 4.8rem;
      font-size: $para3;
      font-weight: 600;
      background-color: $blue;
      border-radius: 0.6rem;
    }
    .cancel-btn {
      background-color: transparent;
      color: $blue;
      border-color: $blue;
    }
  }
}
