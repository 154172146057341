@import "../../css/variables";

.admin-chat-main {
  overflow: auto;
  padding-top: 2rem;
  .messages {
    display: flex;
    margin-bottom: 1rem;
    gap: 0.5rem;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
    .message-info {
      display: flex;
      flex-direction: column;
      color: $darkGrey;
      font-weight: 600;
      font-size: $para3;
      justify-content: flex-end;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .message-contents {
      display: flex;
      flex-direction: column;
      max-width: 80%;
      align-items: flex-end;
      margin-left: 2rem;
      p {
        background-color: #2b4ed2;
        padding: 1rem 2rem;
        font-family: $AlbertSans;
        color: $white;
        border-radius: 1rem 1rem 0rem 1rem;
        max-width: max-content;
        font-size: $para3;
        margin: 0;
      }
      .time {
        font-size: 0.9rem;
        color: $lightGrey;
        font-weight: 600;
        font-family: $AlbertSans;
      }
      span {
        color: $darkGrey;
        font-size: $para3;
        font-weight: 600;
        font-family: $AlbertSans;
      }
      img {
        width: 50%;
      }
    }
    &.owner {
      flex-direction: row-reverse;
      align-items: end;
      .message-contents {
        align-items: flex-start;
      }
      p {
        background-color: $skyBlue;
        color: $darkGrey;
        font-family: $AlbertSans;
        font-size: $para3;
        border-radius: 1rem 1rem 1rem 0rem;
      }
    }
  }
}
