@import "../../../../css/variables";

.project-detail-document {
  .total-document {
    background-color: $headerBg;
    padding: 0.5rem 1.5rem;
    margin-bottom: 1rem;
    h4 {
      font-size: $para2;
      font-weight: 700;
      margin: 0;
    }
    button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0 1.5rem;
      background-color: $white;
      color: $blue;
      border-color: $blue;
      font-size: $para3;
      height: 4rem;
    }
  }
  .document-section {
    gap: 1%;
    .document-card {
      width: 15%;
      min-width: 20rem;
      .img {
        width: 100%;
        height: 12rem;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
          180deg,
          #f7f8fd 0%,
          rgba(255, 255, 255, 0) 100%
        );
        border: 0.5px solid rgba(255, 255, 255, 0);
        svg {
          font-size: 4rem;
        }
      }
      .name {
        font-size: $para2;
        margin: 0.5rem 0;
      }
      .date,
      .time {
        font-size: $para4;
        color: $table-text;
      }
    }
  }
}

@media (max-width: 576px) {
  .project-detail-document {
    .document-section {
      justify-content: space-between;
      .document-card {
        width: 48%;
        min-width: auto;
        margin-bottom: 1rem;
      }
    }
  }
}
