@import "../../../css/variables";

.email-success-section {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background-color: $dark-bule;
  background-image: url(../../../assets/images/partten.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  .email-success-main {
    background-color: $off-white;
    background-image: url(../../../assets/images/fence.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 45%;
    height: 100%;
    .form-inner {
      width: 60%;
      padding-top: 10rem;
      .logo {
        width: 40%;
        margin-bottom: 3rem;
        img {
          width: 100%;
        }
      }
      .content {
        background-color: $white;
        border-radius: 1rem;
        padding: 4rem 2.5rem;
        text-align: center;
        .spinner-border {
          width: 4rem !important;
          height: 4rem !important;
        }
        img {
          width: 15rem;
        }
        h1 {
          font-size: $heading;
          width: 50%;
          margin: auto;
          margin-bottom: 2rem;
        }
        .login-btn {
          margin-bottom: 1.5rem;
          width: 15rem;
          font-size: $para2;
          padding: 1.5rem;
          font-weight: 600;
          font-family: $AlbertSans;
          background-color: $blue;
        }
      }
    }
  }
  .sign-up-image-section {
    display: grid;
    place-content: center;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 768px) {
  .email-success-section {
    .email-success-main {
      .form-inner {
        width: 80%;
      }
    }
  }
}

@media (max-width: 576px) {
  .email-success-section {
    .email-success-main {
      .form-inner {
        padding: 5rem 1rem;
        width: 100%;
        .content {
          h1 {
            font-size: 3rem;
            width: 70%;
          }
          .login-btn {
            padding: 1rem;
          }
        }
      }
    }
  }
}
