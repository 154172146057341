@import "../../../css/variables";

.plan-detail-main {
  .title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.2rem;
    h2 {
      font-size: $heading-small;
      color: $darkGrey;
      font-weight: 500;
      margin: 0;
    }
  }
  .plan-card {
    border: 1px solid $purple;
    border-radius: 1rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    h5 {
      font-size: $para2;
      color: $textLightColor;
      width: 20rem;
      margin-bottom: 1.5rem;
    }
    h4 {
      font-size: $para2;
      color: $textLightColor;
      font-weight: 800;
      margin-bottom: 1.5rem;
    }
    .edit-icon {
      width: 2.4rem;
      height: 2.4rem;
      cursor: pointer;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
    }
    h2 {
      font-size: $heading-small;
      margin-bottom: 1rem;
    }
    .feature-list {
      background-color: #f4f7ff;
      width: 45rem;
      max-width: 100%;
      padding: 1rem;
      border-radius: 0.6rem;
      margin-bottom: 0.2rem;
      h5 {
        width: 100%;
        margin: 0;
      }
      .input-switch {
        input {
          height: 2rem;
          width: 4rem;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .plan-detail-main {
    .plan-card {
      .info {
        h5 {
          width: 15rem;
        }
      }
    }
  }
}
