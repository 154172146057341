@import "../../css/variables";

.upcoming-event {
    border: 1px solid $haederStroke;
    border-radius: 0.8rem;
    padding: 1.5rem;
    height: 100%;
    .heading {
      font-size: $heading-small;
    }
    table {
      margin-top: 1rem;
      margin-bottom: 0;
      thead {
        font-size: $para3;
      }
      tbody {
        tr {
          border-color: transparent;
          td {
            font-size: $para2;
            .img {
              img {
                width: 32px;
                height: 32px;
                object-fit: cover;
              }
            }
            .content {
              font-size: $para4;
              .name {
                color: $darkGrey;
              }
              .designation {
                color: $lightGrey;
              }
            }
          }
        }
      }
    }
  }