@import "../../css/variables";

.chat-analyze {
    background-color: $lightPurple;
    border-radius: 0px 11px 11px 0px;
    box-shadow: 0px 0px 4px 0px rgba(113, 116, 141, 0.25);
    .chat-analyze-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0rem 2rem;
      height: 6.8rem;
      border-bottom: 0.2rem solid #d8dbf3;
      .heading {
        font-size: $para2;
        color: $darkGrey;
        font-weight: 700;
        font-family: $AlbertSans;
      }
      .thread-btn {
        Button {
          padding: 0.8rem 2.5rem;
          border-radius: 8rem;
          font-size: $para3;
          font-weight: 600;
          font-family: $AlbertSans;
        }
      }
    }
    .chat-analyze-content {
      margin-top: 1rem;
      padding: 0rem 1rem;
      .accordion {
        .accordion-item {
          margin-bottom: 1.6rem;
          border-radius: 1rem;
          border: 0;
          .accordion-button:not(.collapsed) {
            background-color: transparent;
            border: 0;
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
          .accordion-button {
            border-radius: 1rem;
            font-size: $para2;
            font-weight: 700;
            color: $black;
            border: 0;
            font-family: $AlbertSans;
            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
          .tags-main {
            display: flex;
            flex-wrap: wrap;
            gap: 0.8rem;
            .tags {
              display: flex;
              background-color: $skyBlue;
              padding: 0.5rem;
              border-radius: 0.8rem;
              gap: 0.5rem;
              font-family: $AlbertSans;
              .tag-name {
                font-size: $para3;
                color: $black;
                font-weight: 500;
              }
              .count {
                background-color: $dark-bule;
                color: $white;
                padding: 0.2rem 0.5rem;
                border-radius: 0.4rem;
                width: 22px;
                height: 22px;
                display: grid;
                place-content: center;
                font-size: $para4;
                font-weight: 700;
              }
            }
          }
          .sentimentals-tag-main {
            display: flex;
            flex-wrap: wrap;
            gap: 0.8rem;
            .tags {
              display: flex;
              padding: 0.5rem 0.9rem;
              border-radius: 0.8rem;
              gap: 0.5rem;
              align-items: center;
            }
            .name {
              font-size: $para3;
              color: $black;
              font-weight: 500;
            }
            .per-cent {
              color: $black;
              font-size: $para4;
              font-weight: 700;
            }
          }
          .speakers-tag-main {
            .table {
              .thead {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
                margin-bottom: 0.5rem;
                .th {
                  font-size: $para4;
                  color: $lightGrey;
                  font-weight: 500;
                  font-family: $AlbertSans;
                }
              }
              .tbody {
                display: flex;
                justify-content: space-between;
                background-color: $lightPurple;
                margin-bottom: 1rem;
                border-radius: 0.8rem;
                .td {
                  padding: 0.8rem;
                  color: $darkGrey;
                  font-weight: 500;
                  font-size: $para3;
                  font-family: $AlbertSans;
                }
              }
            }
          }
        }
      }
    }
  }