@import "../../../../css/variables";

.schedul-demo-form {
  width: 100%;
  padding: 2rem;
  .ant-form-item .ant-form-item-row {
    display: flex;
    align-items: center;
    .ant-form-item-control-input {
      max-width: 60rem;
      margin-left: auto;
    }
  }
  label {
    font-size: $para3;
    font-weight: 700;
  }
  input,
  textarea {
    padding: 1rem;
    width: 100%;
    border: 1px solid $haederStroke;
    font-size: $para2;
  }
  .schedul-date {
    width: 30rem;
    border: 1px solid $haederStroke;
    border-radius: 0.8rem;
  }
  .add-member-footer-btn {
    margin-top: 3rem;
    button {
      height: 4.8rem;
      font-size: $para3;
      font-weight: 600;
      background-color: $blue;
      border-radius: 0.8rem;
      width: 14rem;
    }
    .cancel-btn {
      background-color: transparent;
      color: $blue;
      border-color: $blue;
    }
  }
}
