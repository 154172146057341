@import "../../css/variables";

.team-message-main {
  height: 890px;
  overflow: auto;
  padding-top: 2rem;
  .messages {
    display: flex;
    margin-bottom: 2rem;
    gap: 0.5rem;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2rem;
    .message-info {
      display: flex;
      flex-direction: column;
      color: $darkGrey;
      font-weight: 600;
      font-size: $para3;
      justify-content: flex-end;
    }
    .message-contents {
      display: flex;
      flex-direction: column;
      max-width: 80%;
      align-items: flex-end;
      margin-left: 2rem;
      .sender {
        background-color: #2b4ed2;
        padding: 1rem 2rem;
        font-family: $AlbertSans;
        color: $white;
        border-radius: 1rem 1rem 0rem 1rem;
        max-width: max-content;
        font-size: $para3;
        margin: 0;
      }
      .time {
        font-size: 0.9rem;
        color: $lightGrey;
        font-weight: 600;
        font-family: $AlbertSans;
      }
    }
    &.owner {
      flex-direction: row-reverse;
      align-items: end;
      .message-contents {
        align-items: flex-start;
      }
      .team-member-details {
        background-color: $skyBlue;
        color: $darkGrey;
        font-family: $AlbertSans;
        font-size: $para3;
        padding: 1rem 2rem;
        border-radius: 1rem 1rem 1rem 0rem;
        width: 100%;
        p {
          margin: 0;
        }
        .professinal-details {
          margin-top: 3rem;
        }
      }
    }
  }
}
