@import "../../../css/variables";

.client-detail-tab {
  gap: 1rem;
  padding-bottom: 1rem;
  margin-top: 3.5rem;
  .nav-link {
    border-radius: 1rem;
    padding: 1rem;
    font-size: $para3;
    font-weight: 600;
    color: $lightGrey;
    border: 0;
  }
  .nav-link.active {
    color: $white;
    background-color: $blue;
  }
}
.client-detail-table {
  tbody {
    td {
      font-size: $para2;
      font-weight: 500;
      padding: 1.5rem 1rem;
      color: $textLightColor;
      text-wrap: nowrap;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        width: 30%;
      }
      &:nth-child(4) {
        width: 10%;
      }
    }
  }
}
