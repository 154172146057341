@import "../../../css/variables";

.team-detail-table {
  margin: 0;
  thead {
    th {
      border: 0;
      padding: 1rem !important;
      border-radius: 9px 9px 0px 0px;
      background: #fafbff !important;
      font-size: $para3;
      font-weight: 500;
      text-wrap: nowrap;
      &:nth-child(5) {
        text-align: center;
      }
    }
  }
  tbody {
    tr {
      height: 5.8rem;
    }
    td {
      font-size: $para3;
      font-weight: 500;
      padding: 1rem 1rem;
      color: $table-text !important;
      text-wrap: nowrap;
      &:nth-child(1),
      &:nth-child(2) {
        width: 30%;
      }
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 15%;
      }
    }
    .progress-bar {
      border-radius: 10px;
    }
    .completed {
      border-radius: 3rem;
      background: #f5f6ff;
      padding: 1rem;
      color: $blue;
      font-size: $para2;
      font-weight: 500;
      span {
        margin-right: 1rem;
      }
    }
  }
}
