@import "../../../css/variables";

.opportunity-table-main {
  .project-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    .heading {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
    }
    .create-project-btn {
      Button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border: 0;
        padding: 1rem;
        font-size: $para2;
        font-weight: 600;
        color: $white;
        border-radius: 0.8rem;
        background-color: $dark-bule;
      }
    }
  }
  .nav-tabs {
    gap: 1rem;
    padding-bottom: 1rem;
    .search-section {
      .input-group {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #e7e6e6;
        .form-control {
          border: 0;
          padding: 1rem;
          font-size: $para2;
          color: #9999a8;
          font-weight: 400;
          background-color: transparent;
          &:focus {
            outline: none;
            box-shadow: none;
          }
          &:focus-visible {
            outline: none;
          }
        }
        .input-group-text {
          background-color: transparent !important;
          border: 0 !important;
        }
      }
    }
  }
  .opportunity-tab {
    > .nav-item {
      &:nth-child(3) {
        max-width: 30rem;
        width: 100%;
        margin-left: auto;
        button {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
  .nav-link {
    border-radius: 1rem;
    padding: 1rem 2rem;
    font-size: $para3;
    font-weight: 600;
    color: $lightGrey;
    border: 0;
  }
  .nav-link.active {
    color: $white;
    background-color: $blue;
  }
}

.add-proposal-modal {
  .modal-content {
    border: 0;
    .modal-body {
      border: 0;
      padding: 2.5rem;
      .heading {
        font-size: $para2;
        font-weight: 700;
        text-align: center;
        margin: 1rem 0 3rem;
      }
      button {
        display: block;
        width: 100%;
        height: 4.8rem;
        font-size: $para3;
        font-weight: 600;
        background-color: $blue;
        border-radius: 0.6rem;
        margin-top: 3rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .nav-tabs {
    gap: 0.5rem !important;
  }
  .nav-link {
    padding: 1rem !important;
  }
}

@media (max-width: 576px) {
  .opportunity-table-main {
    .project-list-header {
      .heading {
        font-size: 3rem !important;
      }
      button {
        font-size: 1.4rem !important;
      }
    }
    .opportunity-tab {
      > .nav-item {
        &:nth-child(3) {
          max-width: 100%;
          width: 100%;
          margin-left: auto;
          button {
            width: 100%;
            padding: 0 !important;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}
