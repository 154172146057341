@import "../../../../css/variables";

.member-activity-screen {
  height: 84%;
  .project-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    h2 {
      font-size: $heading-small;
      color: $darkGrey;
      font-weight: 500;
      margin: 0;
    }
  }
  h1 {
    margin: 3rem 0 1rem;
    font-size: 2.5rem;
    font-weight: 700;
  }
  .member-activity-accordion {
    border: 1px solid $haederStroke;
    border-radius: 1rem;
    margin-bottom: 2rem;
    overflow: hidden;
    .accordion-button:not(.collapsed) {
      background-color: transparent;
      box-shadow: none;
      &:focus {
        box-shadow: none;
        outline: 0;
      }
      &:focus-visible {
        box-shadow: none;
        outline: 0;
      }
    }
    .accordion-item {
      background-color: transparent;
      &:focus {
        box-shadow: none;
        outline: 0;
      }
      &:focus-visible {
        box-shadow: none;
        outline: 0;
      }
    }
    .accordion-button {
      font-size: $para2;
      font-weight: 700;
      color: $textColor;
      font-family: $AlbertSans;
      height: 6rem;
      border-radius: 1rem;
      &:focus {
        box-shadow: none;
        outline: 0;
      }
      &:focus-visible {
        box-shadow: none;
        outline: 0;
      }
    }
    .accordion-body {
      padding: 0.5rem 1.5rem 2rem;
      border-top: 1px solid $haederStroke;
      .daily-activities {
        font-size: $para2;
        font-weight: 700;
        color: $textColor;
        .activity {
          height: 4.8rem;
          border-bottom: 1px solid $haederStroke;
          display: flex;
          align-items: center;
          gap: 4rem;
          .work {
            font-weight: 500;
            color: $textLightColor;
          }
        }
      }
    }
  }
}
