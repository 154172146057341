@import "../../../css/variables";

.agency-detail-main {
  font-family: $AlbertSans;
  .title {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2.2rem;
    h2 {
      font-size: $heading-small;
      color: $darkGrey;
      font-weight: 500;
      margin: 0;
    }
  }
  .image {
    border: 1px solid $purple;
    border-radius: 1rem;
    img {
      width: 25rem;
      height: 27rem;
    }
  }
  .agency-detail-section {
    width: 100%;
    .agency-card {
      border: 1px solid $purple;
      border-radius: 1rem;
      padding: 1.5rem;
      margin-bottom: 1.5rem;
      position: relative;
      .info {
        width: 100%;
      }
      .card-title {
        font-size: $para2;
        font-weight: 700;
        margin-bottom: 2rem;
      }
      .name {
        width: 18rem;
      }
      h5 {
        font-size: $para4;
        color: $lightGrey;
      }
      h4 {
        font-size: $para2;
        color: $textColor;
        margin-bottom: 1.5rem;
      }
      .block-btn {
        font-size: $para2;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        height: fit-content;
        background-color: #f34c4c;
        border: none;
        border-radius: 0.8rem;
        text-wrap: nowrap;
      }
      .inner-card {
        background-color: #e4e9ff;
        padding: 1.5rem;
        border-radius: 0.6rem;
        h3 {
          font-size: $heading-medium;
          font-weight: 700;
          color: $blue;
          margin-bottom: 1.5rem;
        }
        .name {
          width: 33%;
        }
      }
      .green {
        background-color: #e9ffe6;
      }
    }
    .add-member-footer-btn {
      margin-top: 3rem;
      button {
        height: 4.8rem;
        font-size: $para3;
        padding: 0 2rem;
        font-weight: 600;
        background-color: $blue;
        border-radius: 0.8rem;
        border: none;
      }
      .reset-btn {
        background-color: $dark-bule;
      }
    }
  }
}
