@import "../../../css/variables";

.sign-up-section-main {
  height: 100%;
  min-height: 100vh;
  display: flex;
  background-color: $dark-bule;
  background-image: url(../../../assets/images/partten.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  .signup-form-main {
    background-color: $off-white;
    background-image: url(../../../assets/images/fence.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 45%;
    height: 100%;
    .form-inner {
      width: 60%;
      padding-top: 10rem;
      .logo {
        width: 40%;
        margin-bottom: 3rem;
        img {
          width: 100%;
        }
      }
      form {
        background-color: $white;
        border-radius: 1rem;
        padding: 4rem 2.5rem;
        .form-header {
          border-bottom: 1px solid $haederStroke;
          font-family: $AlbertSans;
          h1 {
            font-size: $heading-large;
            color: $textColor;
            font-weight: 700;
            margin-bottom: 1rem;
          }
          h4 {
            color: $textLightColor;
            font-size: $para1;
            font-weight: 500;
            margin-bottom: 3rem;
          }
        }
        .form-content {
          padding-top: 2.5rem;
          label {
            font-size: $para4;
            color: $textColor;
            font-weight: 500;
            margin-bottom: 0.5rem;
          }
          .input-field {
            position: relative;
            margin-bottom: 2rem;
            input {
              width: 100%;
              background-color: $lightPurple;
              border: 0;
              padding: 1.5rem;
              border-radius: 0.5rem;
              font-size: $para3;
              &:focus {
                border: 0;
              }
              &:focus-visible {
                border: 0;
                outline: 0;
              }
            }
            .icon {
              position: absolute;
              top: 30%;
              right: 2rem;
            }
          }
          .check {
            min-width: 2rem;
            min-height: 2rem;
            border: 1px solid #e3e3e3;
            &:focus {
              border: 0;
            }
            &:focus-visible {
              border: 0;
              outline: 0;
            }
          }
          .signup-agency {
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;
            label {
              font-size: 1.5rem;
              font-weight: 400;
              color: $textLightColor;
              margin-bottom: 0;
            }
          }
          .terms-policy {
            display: flex;
            align-items: flex-start;
            gap: 1rem;
            margin-top: 2rem;
            span {
              font-size: 1.5rem;
              font-weight: 400;
              color: $textLightColor;
              a {
                color: $textLightColor;
                font-weight: 700;
              }
            }
          }
          .login-screen-divider {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 1.5rem;
            color: $textLightColor;
            margin: 2rem 0;
            .text {
              width: 50%;
              text-align: center;
            }
            hr {
              border: 1.5px solid $purple;
              width: 100%;
            }
          }
          .sign-up-with-icon-group {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5rem;
            .icon {
              cursor: pointer;
            }
          }
          .sign-up-btn {
            margin: 2rem 0;
            width: 100%;
            font-size: $para2;
            padding: 1.5rem;
            font-weight: 600;
            font-family: $AlbertSans;
            background-color: $blue;
          }
          .login-idicator {
            text-align: center;
            font-size: 15px;
            color: $textLightColor;
            font-weight: 400;
            span {
              color: $textLightColor;
              font-weight: 700;
              cursor: pointer;
              border-bottom: 1px solid $textLightColor;
              margin-bottom: 0;
            }
          }
        }
        .please-verification-msg {
          .title {
            font-size: $heading-small;
            border-bottom: 1px solid $haederStroke;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            svg {
              font-size: 3rem;
              margin-right: 0.5rem;
              color: #159900;
            }
          }
          .content {
            font-size: $para2;
          }
        }
      }
    }
  }
  .sign-up-image-section {
    display: grid;
    place-content: center;
    height: 100%;
    img {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .sign-up-section-main {
    .signup-form-main {
      .form-inner {
        width: 80%;
        form {
          .form-content {
            padding-top: 2rem;
            > div {
              flex-direction: column;
              > div {
                width: 100% !important;
              }
            }
            .input-field {
              margin-bottom: 1rem;
            }
            .signup-agency,
            .terms-policy {
              flex-direction: row;
              margin: 1rem 0 1.5rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .sign-up-section-main {
    .signup-form-main {
      .form-inner {
        padding: 4rem 1rem;
        width: 100%;
        form {
          padding: 3rem 2rem;
          .form-header {
            h1 {
              font-size: 3rem;
            }
            h4 {
              font-size: $para2;
              margin-bottom: 2rem;
            }
          }
          .form-content {
            padding-top: 2rem;
          }
          .remember-me {
            margin: 2rem 0;
          }
          .sign-up-btn {
            padding: 1rem !important;
          }
        }
      }
    }
  }
}
