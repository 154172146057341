@import "../../../css/variables";

.profile-modal {
  font-family: $AlbertSans;
  .modal-content {
    margin: 0 auto;
    border: 0;
    .modal-body {
      padding: 3rem 4rem;
      h1 {
        font-size: $heading;
        color: $textColor;
        font-weight: 500;
        margin-bottom: 2rem;
        text-align: center;
      }
      .image {
        width: 12rem;
        aspect-ratio: 1/1;
        margin: auto;
        img {
          width: 12rem;
          height: 12rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .update-btn {
        background-color: $blue;
        font-size: $para4;
        width: 15rem;
        height: 3.5rem;
        border-radius: 0.8rem;
        margin: 2rem auto;
        display: block;
      }
      .add-opportunity-form {
        width: 100%;
        label {
          font-size: $para3;
          font-weight: 700;
        }
        input {
          padding: 1.5rem;
          width: 100%;
          height: 5.4rem;
          border: 1px solid $haederStroke;
          font-size: $para2;
        }
      }
      .add-member-footer-btn {
        margin-top: 3rem;
        button {
          width: 14rem;
          height: 4.8rem;
          font-size: $para3;
          font-weight: 600;
          background-color: $blue;
          border-radius: 0.6rem;
        }
        .cancel-btn {
          background-color: transparent;
          color: $blue;
          border-color: $blue;
        }
      }
    }
  }
}
