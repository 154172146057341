@import "../../../css/variables";
.project-screen-main {
  font-family: $AlbertSans;
  .project-screen-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    .heading {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
    }
    .create-project-btn {
      Button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border: 0;
        padding: 1rem;
        font-size: $para2;
        font-weight: 600;
        border-radius: 0.8rem;
        background-color: $dark-bule;
      }
    }
  }
  .table-header {
    .categories-main {
      .categories-text {
        font-size: $para3;
        font-weight: 600;
        color: $lightGrey;
        padding: 1rem;
        border-radius: 0.8rem;
        cursor: pointer;
      }
      .nav-tabs {
        gap: 1rem;
        padding-bottom: 1rem;
      }
      .nav-link {
        border-radius: 1rem;
        padding: 1rem;
        font-size: $para3;
        font-weight: 600;
        color: $lightGrey;
        border: 0;
      }
      .nav-link.active {
        color: $white;
        background-color: $blue;
      }
    }
    .dropdown {
      float: right;
      height: 51px;
      border-bottom: 1px solid #e7e6e6;
      .dropdown-toggle {
        background-color: transparent;
        color: $black;
        font-size: $para3;
        font-weight: 600;
        &::after {
          content: url("../../../assets/Icon/down-arrow-icon.svg");
          border: 0;
          margin-left: 1rem;
          vertical-align: 0;
        }
      }
      .droup-item {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-bottom: 1rem;
        font-size: $para3;
        font-weight: 500;
        color: $darkGrey;
        input {
          width: 20px;
          height: 20px;
          &:is(:focus, :focus-visible) {
            outline: none;
            border: 0;
          }
        }
      }
      .dropdown-menu.show {
        transform: translate(-20px, 39px) !important;
        padding: 1rem;
        width: 150% !important;
      }
    }
  }
  .project-list-table {

  }
  .pagination-main {
    display: flex;
    justify-content: flex-end;
    .ant-pagination {
      display: flex;
      align-items: center;
      .ant-pagination-options {
        display: none;
      }
      .anticon {
        color: $blue;
        font-weight: 700;
        vertical-align: 0;
      }
      .ant-pagination-item {
        font-size: $para4;
        color: $lightGrey;
        border: 0.5px solid #e7e6e6;
        border-radius: 5px;
        &:hover {
          background-color: transparent;
        }
      }
      .ant-pagination-item-active a {
        border-radius: 5px;
        background-color: $blue;
        color: $white;
      }
    }
  }
}

@media (max-width: 576px) {
  .project-screen-main {
    .project-screen-header {
      .heading {
        font-size: 3rem !important;
      }
      button {
        font-size: 1.4rem !important;
      }
    }
  }
}
