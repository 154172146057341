@import "../../../css/variables";

.help-section {
  .project-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.2rem;
    .heading {
      margin: 0;
      font-size: $heading;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
    }
    .create-project-btn {
      Button {
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 0;
        padding: 1rem;
        border-radius: 0.8rem;
        background-color: $dark-bule;
        .btn-text {
          font-size: $para3;
          font-weight: 600;
          color: $white;
        }
      }
    }
  }
  .nav-tabs {
    gap: 1rem;
    padding-bottom: 1rem;
  }
  .nav-link {
    border-radius: 1rem;
    padding: 1rem 2rem;
    font-size: $para3;
    font-weight: 600;
    color: $lightGrey;
    border: 0;
  }
  .nav-link.active {
    color: $white;
    background-color: $blue;
  }
}

.add-proposal-modal {
  .modal-content {
    border: 0;
    .modal-body {
      border: 0;
      padding: 2.5rem;
      .heading {
        font-size: $para2;
        font-weight: 700;
        text-align: center;
        margin: 1rem 0 3rem;
      }
      button {
        display: block;
        width: 100%;
        height: 4.8rem;
        font-size: $para3;
        font-weight: 600;
        background-color: $blue;
        border-radius: 0.6rem;
        margin-top: 3rem;
      }
    }
  }
}
