@import "../../css/variables";

.client-table {
  thead {
    border: 0;
    th {
      border: 0;
      padding: 1rem;
      border-radius: 9px 9px 0px 0px;
      background: #fafbff;
      font-size: $para2;
      font-weight: 500;
      text-wrap: nowrap;
    }
  }
  tbody {
    vertical-align: middle;
    .no-data-found {
      text-align: center;
    }
    td {
      font-size: $para2;
      font-weight: 500;
      color: $table-text;
      padding: 1rem;
      text-wrap: nowrap;
      &:nth-child(1) {
        width: 11%;
      }
      &:nth-child(2),
      &:nth-child(4) {
        width: 16%;
      }
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        width: 13%;
      }
      &:nth-child(8) {
        width: 5%;
      }
    }
    .client-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .client-avatar {
        font-size: 29.091px;
        color: $darkGrey;
        background-color: #2bd2b4;
        width: 3.6rem;
        height: 3.6rem;
        display: grid;
        place-content: center;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
    }
  }
}
