@import "../../../css/variables";

.assign-manager-modal {
  font-family: $AlbertSans;
  z-index: 1050;
  .modal-content {
    margin: 0 auto;
    border: 0;
    .modal-body {
      padding: 0;
      .header {
        padding: 1.5rem 2rem;
        background-color: $dark-bule;
        h1 {
          font-size: $heading-medium;
          font-weight: 600;
          color: $white;
          margin: 0;
        }
        svg {
          font-size: 2rem;
          color: $white;
          cursor: pointer;
        }
      }
      .content {
        padding: 2rem;
        height: 60vh;
        overflow: auto;
        .manager-check-card {
          background-color: #e8ecf7;
          padding: 1rem 1.5rem;
          border-radius: 1rem;
          margin-bottom: 1rem;
          .check-input {
            display: flex;
            align-items: center;
            gap: 1rem;
            .check {
              width: 2rem;
              height: 2rem;
              border: 1px solid #e3e3e3;
              margin-right: 1rem;
              &:focus {
                border: 0;
              }
              &:focus-visible {
                border: 0;
                outline: 0;
              }
            }
            .client-details {
              display: flex;
              align-items: center;
              gap: 1rem;
              width: 100%;
              .client-avatar {
                font-size: 29.091px;
                color: $darkGrey;
                background-color: #2bd2b4;
                width: 6rem;
                height: 6rem;
                display: grid;
                place-content: center;
                border-radius: 50%;
                overflow: hidden;
                img {
                  width: 100%;
                }
              }
              .detail {
                h4 {
                  font-size: $para3;
                  margin-bottom: 0.5rem;
                }
                h5 {
                  font-size: $para4;
                  color: $lightGrey;
                  margin: 0;
                }
              }
              .project-assigned {
                font-size: $para2;
                font-weight: 500;
                margin-left: auto;
              }
            }
          }
        }
      }
    }
  }
}
