@import "../../css/variables";

.chat-box-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2rem;
    height: 6.8rem;
    .title {
      font-size: $para2;
      color: $darkGrey;
      font-weight: 500;
      font-family: $AlbertSans;
      span {
        background-color: #f34c4c;
        padding: 0.5rem;
        color: $white;
        border-radius: 50%;
        font-size: $para4;
        font-weight: 600;
        margin-left: 0.2rem;
      }
    }
    .filter-dropdown {
      .dropdown {
        .dropdown-toggle {
          font-size: $para4;

          .bnt {
            font-size: $para4 !important;
          }
        }
      }
    }
  }